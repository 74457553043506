import PrimaryButton, {SecondaryButton} from '../Button/Button'
import clsx from 'clsx'
import {UserMarket} from '../../hooks/useMarkets'
import useFaucet from '../../hooks/useFaucet'
import {useMemo, useState} from 'react'
import useApproveContract from '../../hooks/useApproveContract'
import {TransactionStatus} from '@usedapp/core'
import Modal from './Modal'
import {SpinnerModal} from './SpinnerModal'
import useMoveAssets from '../../hooks/useMoveAssets'
import useAccountLiquidityCalculations from '../../hooks/useAccountLiquidityCalculations'
import useCollateral from '../../hooks/useCollateral'
import formatCashValue from '../../utils/formatCashValue'
import {UsableBigNumber} from '../../utils/bigNumber'

type TransactionTypes = 'enable' | 'supply' | 'withdraw' | 'faucet'

export function SupplyMarketModal({market}: {market: UserMarket}) {
	const [open, setOpen] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<'Supply' | 'Withdraw'>('Supply')
	const [value, setValue] = useState<string>('')
	const [withdrawAll, setWithdrawAll] = useState(false)
	const [currentTxType, setCurrentTxType] = useState<
		TransactionTypes | undefined
	>(undefined)
	const [isEnabled, enableMarket, marketEnableTxState] = useApproveContract(
		market.cTokenAddress,
		market.underlyingAssetAddress,
		market.asset.type === 'base'
	)
	const {txState, supplyAsset, withdrawAsset} = useMoveAssets(market)
	const {faucetAllowed, getMockToken, faucetState} = useFaucet(market)
	const {newValues} = useAccountLiquidityCalculations(
		market,
		value,
		activeTab.toLowerCase() as 'supply' | 'withdraw'
	)

	const currentTxState = useMemo<TransactionStatus>(() => {
		switch (currentTxType) {
			case 'enable':
				return marketEnableTxState
			case 'supply':
			case 'withdraw':
				return txState
			case 'faucet':
				return faucetState
			default:
				return {
					status: 'None',
				}
		}
	}, [currentTxType, txState, marketEnableTxState, faucetState])

	function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (activeTab === 'Withdraw' && withdrawAll) {
			setWithdrawAll(false)
		}

		setValue(event.target.value)
	}

	function handleMaxButtonClick() {
		let maxAmount =
			market[activeTab === 'Supply' ? 'walletBalance' : 'balance'].rounded

		if (activeTab === 'Supply' && market.asset.type === 'base') {
			maxAmount -= 0.1
		}

		setValue(maxAmount.toString())
		if (activeTab === 'Withdraw') {
			setWithdrawAll(true)
		}
	}

	async function onConfirm() {
		setOpen(true)
		if (activeTab === 'Withdraw') {
			setCurrentTxType('withdraw')
			await withdrawAsset(value, withdrawAll)
		} else if (isEnabled) {
			setCurrentTxType('supply')
			await supplyAsset(value)
		} else {
			setCurrentTxType('enable')
			await enableMarket()
		}
	}

	async function onFaucet() {
		setOpen(true)
		setCurrentTxType('faucet')
		await getMockToken()
	}

	return (
		<>
			<Modal isOpened={open} setIsOpened={setOpen}>
				<SpinnerModal
					setOpen={setOpen}
					txStatus={currentTxState.status}
					txHash={currentTxState.transaction?.hash}
					txErrorMessage={currentTxState.errorMessage}
				/>
			</Modal>
			<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full mb-4">
				<img
					className="w-full h-full rounded-full"
					src={market.asset.logoURI}
					alt={market.asset.name + ' logo'}
					aria-hidden="true"
				/>
			</div>
			<h3 className="text-xl leading-6 mb-4 font-semibold text-center">
				{market.asset.name}
			</h3>
			{(isEnabled || activeTab === 'Withdraw') && (
				<>
					<div className="relative">
						<input
							autoFocus
							type="tel"
							inputMode="numeric"
							className="w-full bg-gray-900 text-3xl pl-5 pr-12 py-3 rounded-lg border border-gray-800 text-center focus:outline-none truncate"
							style={{
								boxShadow: 'inset #000000 0px 0px 25px 0px',
							}}
							value={value}
							onChange={handleInputChange}
						/>
						<button
							type="button"
							className="absolute top-1/2 -translate-y-1/2 right-3 rounded-md font-medium text-sm text-yellow-400 hover:underline transition-all"
							onClick={handleMaxButtonClick}
						>
							Max
						</button>
					</div>
					<p className="mt-2 mb-4 flex gap-3 justify-center">
						<span>
							{activeTab === 'Supply'
								? 'Wallet balance:'
								: 'Currently supplying:'}
						</span>
						<span>
							{
								market[activeTab === 'Supply' ? 'walletBalance' : 'balance']
									.rounded
							}
						</span>
					</p>
				</>
			)}
			<p className="mb-4 text-sm text-gray-500 text-center"></p>
			<Tabs
				market={market}
				setActiveTab={setActiveTab}
				isEnabled={isEnabled}
				isFaucetAllowed={faucetAllowed}
				disableConfirmButton={
					Number.isNaN(Number(value)) ||
					Number(value) >
						market[activeTab === 'Supply' ? 'walletBalance' : 'balance'].rounded
				}
				onConfirmButtonClick={onConfirm}
				onFaucetButtonClick={onFaucet}
				newValues={newValues}
			/>
		</>
	)
}

function Tabs({
	market,
	setActiveTab,
	isEnabled,
	isFaucetAllowed,
	disableConfirmButton,
	onConfirmButtonClick,
	onFaucetButtonClick,
	newValues,
}: {
	market: UserMarket
	setActiveTab: React.Dispatch<React.SetStateAction<'Supply' | 'Withdraw'>>
	isEnabled: boolean
	isFaucetAllowed: boolean
	disableConfirmButton: boolean
	onConfirmButtonClick: () => Promise<void>
	onFaucetButtonClick: () => Promise<void>
	newValues?: {
		totalSupplied: UsableBigNumber
		totalBorrowed: UsableBigNumber
		borrowLimit: UsableBigNumber
		borrowLimitUsed: string
	}
}) {
	const {borrowLimit, borrowLimitUsed} = useAccountLiquidityCalculations(market)
	const {isEnabledAsCollateral} = useCollateral(market)
	const [tabs, setTabs] = useState<
		{
			name: 'Supply' | 'Withdraw'
			current: boolean
		}[]
	>([
		{name: 'Supply', current: true},
		{name: 'Withdraw', current: false},
	])
	const showDistributionApy = false
	const showBorrowLimit = !(
		tabs.filter(tab => tab.current)[0].name === 'Supply' && !isEnabled
	)
	const notEnoughCollateral = newValues?.borrowLimitUsed === '100'

	return (
		<div>
			<div className="border-b border-gray-700 -mb-px flex" aria-label="Tabs">
				{tabs.map(tab => (
					<button
						key={tab.name}
						onClick={() => {
							const newTabs = tabs.map(t => ({
								...t,
								current: t.name === tab.name,
							}))
							setTabs(newTabs)
							setActiveTab(newTabs.filter(tab => tab.current)[0].name)
						}}
						className={clsx(
							'w-1/2 py-4 px-1 text-center border-b-2 font-medium',
							tab.current
								? 'border-yellow-400 text-yellow-400'
								: 'border-transparent text-gray-500 hover:text-white hover:border-gray-700'
						)}
					>
						{tab.name}
					</button>
				))}
			</div>

			<div className="pt-6 pb-7 flex flex-col gap-4 text-left">
				<p>
					<a
						href="/"
						className="text-yellow-400 hover:underline focus:outline-none"
					>
						View supply rates
					</a>
				</p>
				<div className="flex gap-3 items-center">
					<div className="h-7 w-7 rounded-full flex-shrink-0">
						<img
							className="w-full h-full rounded-full"
							src={market.asset.logoURI}
							alt=""
							aria-hidden="true"
						/>
					</div>
					<p className="flex-1">Supply APY</p>
					<p>{market.apy} %</p>
				</div>
				{showDistributionApy && (
					<div className="flex gap-3 items-center">
						<div className="h-7 w-7 rounded-full flex-shrink-0">
							<img
								className="w-full h-full rounded-full"
								src="https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/gnosis/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d/logo.png"
								alt=""
								aria-hidden="true"
							/>
						</div>
						<p className="flex-1">Distribution APY</p>
						<p>27.83 %</p>
					</div>
				)}
				{showBorrowLimit && (
					<>
						<div className="flex gap-3">
							<p className="flex-1">Borrow limit</p>
							<p>
								$ {formatCashValue(borrowLimit.rounded, 2)}{' '}
								<span className="text-blue-500">&rarr;</span> ${' '}
								{isEnabledAsCollateral && newValues
									? formatCashValue(newValues.borrowLimit.rounded, 2)
									: formatCashValue(borrowLimit.rounded, 2)}
							</p>
						</div>

						<div className="flex gap-3">
							<p className="flex-1">Borrow limit used</p>
							<p>
								{borrowLimitUsed} %{' '}
								<span className="text-blue-500">&rarr;</span>{' '}
								{isEnabledAsCollateral && newValues
									? newValues.borrowLimitUsed
									: borrowLimitUsed}{' '}
								%
							</p>
						</div>
					</>
				)}
			</div>
			<div className="flex flex-col gap-4">
				<PrimaryButton
					className="w-full"
					onClick={onConfirmButtonClick}
					disabled={disableConfirmButton || notEnoughCollateral}
				>
					{tabs.filter(tab => tab.current)[0].name === 'Withdraw'
						? 'Withdraw'
						: !isEnabled
						? 'Enable'
						: 'Supply'}
				</PrimaryButton>
				{(isEnabled ||
					tabs.filter(tab => tab.current)[0].name === 'Withdraw') &&
					isFaucetAllowed && (
						<SecondaryButton className="w-full" onClick={onFaucetButtonClick}>
							Faucet
						</SecondaryButton>
					)}
			</div>
		</div>
	)
}
