import {TransactionStatus, useEthers} from '@usedapp/core'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {BigNumber} from 'ethers'
import Modal from './Modal'
import PrimaryButton from '../Button/Button'
import {SpinnerModal} from './SpinnerModal'
import transformBigNumber, {UsableBigNumber} from '../../utils/bigNumber'
import {UserVault} from '../../hooks/useVaults'
import assetValueToBigNumber from '../../utils/assetValueToBigNumber'
import clsx from 'clsx'
import useApproveContract from '../../hooks/useApproveContract'
import useSingleVault from '../../hooks/useSingleVault'
import {InfoIcon} from '../Icon'

type TransactionTypes = 'enableAssets' | 'enableShares' | 'deposit' | 'withdraw'

export function VaultModal({vault}: {vault: UserVault}) {
	const [open, setOpen] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<'Deposit' | 'Withdraw'>('Deposit')
	const [withdrawAll, setWithdrawAll] = useState(false)
	const [value, setValue] = useState<{
		str: string
		num: number
		bn: BigNumber
	}>({str: '', num: 0, bn: BigNumber.from(0)})
	const [currentTxType, setCurrentTxType] = useState<
		TransactionTypes | undefined
	>(undefined)

	const {account} = useEthers()

	const [isEnabledAssets, enableRouterAssets, routerEnableAssetsTxState] =
		useApproveContract(
			vault.routerAddress,
			vault.asset.address,
			vault.asset.isWrappedNative
		)
	const [isEnabledShares, enableRouterShares, routerEnableSharesTxState] =
		useApproveContract(vault.routerAddress, vault.address, false)
	const {deposit, withdraw, txState, maxWithdraw} = useSingleVault(vault)

	const displayInput =
		(activeTab === 'Deposit' && isEnabledAssets) ||
		(activeTab === 'Withdraw' && isEnabledShares)

	const balance = vault[activeTab === 'Deposit' ? 'walletBalance' : 'balance']
	const displayBalance =
		balance.bn.gt(0) && balance.rounded < 0.0001 ? '< 0.0001' : balance.rounded

	const currentTxState = useMemo<TransactionStatus>(() => {
		switch (currentTxType) {
			case 'enableAssets':
				return routerEnableAssetsTxState
			case 'enableShares':
				return routerEnableSharesTxState
			case 'deposit':
			case 'withdraw':
				return txState
			default:
				return {
					status: 'None',
				}
		}
	}, [
		currentTxType,
		txState,
		routerEnableAssetsTxState,
		routerEnableSharesTxState,
	])

	const convertAndSetInput = useCallback(
		(str: string) => {
			const num = Number(str)
			const isNaN = Number.isNaN(num)
			const bn = assetValueToBigNumber(isNaN ? '0' : str, vault.decimals)

			setValue({str, num, bn})
		},
		[vault.decimals]
	)

	useEffect(() => {
		if (activeTab === 'Withdraw' && withdrawAll)
			convertAndSetInput(maxWithdraw.stringed)
	}, [withdrawAll, maxWithdraw, activeTab, convertAndSetInput])

	useEffect(() => {
		setWithdrawAll(false)
	}, [activeTab])

	function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (activeTab === 'Withdraw' && withdrawAll) {
			setWithdrawAll(false)
		}

		convertAndSetInput(event.target.value)
	}

	function handleMaxButtonClick() {
		let maxAmount = activeTab === 'Deposit' ? vault.walletBalance : maxWithdraw

		if (activeTab === 'Deposit' && vault.asset.isWrappedNative) {
			const gasFeeBufor = BigNumber.from(10).pow(16)
			maxAmount = transformBigNumber(
				maxAmount.bn.sub(gasFeeBufor),
				vault.decimals
			)
		}

		convertAndSetInput(maxAmount.stringed)
		if (activeTab === 'Withdraw') {
			setWithdrawAll(true)
		}
	}

	async function onConfirm() {
		setOpen(true)
		if (activeTab === 'Withdraw') {
			if (isEnabledShares) {
				setCurrentTxType('withdraw')
				await withdraw(value.bn, withdrawAll)
			} else {
				setCurrentTxType('enableShares')
				await enableRouterShares()
			}
		} else {
			if (isEnabledAssets) {
				setCurrentTxType('deposit')
				await deposit(value.bn)
			} else {
				setCurrentTxType('enableAssets')
				await enableRouterAssets()
			}
		}
	}

	return (
		<>
			<Modal isOpened={open} setIsOpened={setOpen}>
				<SpinnerModal
					setOpen={setOpen}
					txStatus={currentTxState.status}
					txHash={currentTxState.transaction?.hash}
					txErrorMessage={currentTxState.errorMessage}
				/>
			</Modal>
			<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full mb-4">
				<img
					className="w-full h-full rounded-full"
					src={vault.asset.iconUrl}
					alt={vault.asset.symbol + ' logo'}
					aria-hidden="true"
				/>
			</div>
			<h3 className="text-xl leading-6 mb-4 font-semibold text-center">
				{vault.name}
			</h3>
			{displayInput && (
				<>
					<div className="relative">
						<input
							autoFocus
							type="tel"
							inputMode="numeric"
							className="w-full bg-gray-900 text-3xl pl-5 pr-12 py-3 rounded-lg border border-gray-800 text-center focus:outline-none truncate"
							style={{
								boxShadow: 'inset #000000 0px 0px 25px 0px',
							}}
							value={value.str}
							onChange={handleInputChange}
						/>
						<button
							type="button"
							className="absolute top-1/2 -translate-y-1/2 right-3 rounded-md font-medium text-sm text-yellow-400 hover:underline transition-all"
							onClick={handleMaxButtonClick}
						>
							Max
						</button>
					</div>
					<p className="mt-2 mb-4 flex gap-3 justify-center">
						<span>
							{activeTab === 'Deposit'
								? 'Wallet balance:'
								: 'Currently depositing:'}
						</span>
						<span>{displayBalance}</span>
					</p>
				</>
			)}
			<p className="mb-4 text-sm text-gray-500 text-center"></p>
			<Tabs
				vault={vault}
				setActiveTab={setActiveTab}
				isEnabledAssets={isEnabledAssets}
				isEnabledShares={isEnabledShares}
				disableConfirmButton={
					!account ||
					(((activeTab === 'Deposit' && isEnabledAssets) ||
						(activeTab === 'Withdraw' && isEnabledShares)) &&
						value.num === 0) ||
					Number.isNaN(value.num) ||
					value.bn.lt(0) ||
					value.str.split('.')[1]?.length > vault.decimals ||
					(!withdrawAll &&
						value.bn.gt(
							activeTab === 'Deposit' ? vault.walletBalance.bn : maxWithdraw.bn
						))
				}
				onConfirmButtonClick={onConfirm}
			/>
		</>
	)
}

function Tabs({
	vault,
	setActiveTab,
	isEnabledAssets,
	isEnabledShares,
	disableConfirmButton,
	onConfirmButtonClick,
	newValues,
}: {
	vault: UserVault
	setActiveTab: React.Dispatch<React.SetStateAction<'Deposit' | 'Withdraw'>>
	isEnabledAssets: boolean
	isEnabledShares: boolean
	disableConfirmButton: boolean
	onConfirmButtonClick: () => Promise<void>
	newValues?: {
		totalSupplied: UsableBigNumber
		totalBorrowed: UsableBigNumber
		borrowLimit: UsableBigNumber
		borrowLimitUsed: string
	}
}) {
	const [tabs, setTabs] = useState<
		{
			name: 'Deposit' | 'Withdraw'
			current: boolean
		}[]
	>([
		{name: 'Deposit', current: true},
		{name: 'Withdraw', current: false},
	])
	const notEnoughCollateral = newValues?.borrowLimitUsed === '100'
	const depositTabButtonTxt = isEnabledAssets ? 'Deposit' : 'Enable'
	const withdrawTabButtonTxt = isEnabledShares ? 'Withdraw' : 'Enable'

	return (
		<div>
			<div className="border-b border-gray-700 -mb-px flex" aria-label="Tabs">
				{tabs.map(tab => (
					<button
						key={tab.name}
						onClick={() => {
							const newTabs = tabs.map(t => ({
								...t,
								current: t.name === tab.name,
							}))
							setTabs(newTabs)
							setActiveTab(newTabs.filter(tab => tab.current)[0].name)
						}}
						className={clsx(
							'w-1/2 py-4 px-1 text-center border-b-2 font-medium',
							tab.current
								? 'border-yellow-400 text-yellow-400'
								: 'border-transparent text-gray-500 hover:text-white hover:border-gray-700'
						)}
					>
						{tab.name}
					</button>
				))}
			</div>

			<div className="pt-6 pb-7 flex flex-col gap-4 text-left">
				<div className="flex gap-3 items-center">
					<div className="h-7 w-7 rounded-full flex-shrink-0">
						<img
							className="w-full h-full rounded-full"
							src={vault.asset.iconUrl}
							alt=""
							aria-hidden="true"
						/>
					</div>
					<p className="flex-1">Deposit APY</p>
					<p>{vault.apy} %</p>
				</div>
				<div className="flex gap-3">
					<p className="flex-1 flex flex-row gap-2 items-center">
						Harvest Fee{' '}
						<span
							className="cursor-help"
							title="Harvest is being performed every day automatically. The fee is collected from the profit."
						>
							<InfoIcon />
						</span>
					</p>
					<p>{vault.harvestFee.rounded * 100} %</p>
				</div>
				<div className="flex gap-3">
					<p className="flex-1 flex flex-row gap-2 items-center">
						Withdrawal Fee{' '}
						<span
							className="cursor-help"
							title="The fee is charged when you withdraw your funds."
						>
							<InfoIcon />
						</span>
					</p>
					<p>{vault.withdrawalFee.rounded * 100} %</p>
				</div>
				<div className="flex gap-3">
					<p className="flex-1 flex flex-row gap-2 items-center">
						Vault address
					</p>
					<a
						href={`https://gnosisscan.io/address/${vault.address}`}
						target="_blank"
					>
						{vault.address.slice(0, 6) + '...' + vault.address.slice(-4)}
					</a>
				</div>
			</div>
			<div className="flex flex-col gap-4">
				<PrimaryButton
					className="w-full"
					onClick={onConfirmButtonClick}
					disabled={disableConfirmButton || notEnoughCollateral}
				>
					{tabs.filter(tab => tab.current)[0].name === 'Deposit'
						? depositTabButtonTxt
						: withdrawTabButtonTxt}
				</PrimaryButton>
			</div>
		</div>
	)
}
