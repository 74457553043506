import {BigNumber, FixedNumber} from 'ethers'

export default function assetValueToBigNumber(
	value: string,
	decimals: number
): BigNumber {
	const multiplier = BigNumber.from(10).pow(decimals)
	const fixedMultiplier = FixedNumber.from(multiplier)

	const splitted = value.split('.')
	const integer = splitted[0]
	// We slice the decimals to the length of the asset decimals
	const decimal = splitted[1]?.slice(0, decimals) || '0'

	// Make it FixedNumber first in case value has floating point
	// BigNumber supports only integer values see: https://github.com/ethers-io/ethers.js/issues/488
	const formattedValue = FixedNumber.fromString(`${integer}.${decimal}`)
		.mulUnsafe(fixedMultiplier)
		.toString()
		.split('.')[0]

	return BigNumber.from(formattedValue)
}
