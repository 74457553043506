import {Link} from 'react-router-dom'
import {SecondaryButton} from '../Button/Button'

interface UnderConstructionModalProps {
	children: React.ReactNode
	url: string
	buttonText: string
}

export default function UnderConstructionModal({
	children,
	url,
	buttonText,
}: UnderConstructionModalProps) {
	return (
		<div>
			<h1 className="text-2xl leading-6 mb-6 font-semibold text-center">
				Coming Soon
			</h1>
			<p className="text-center mb-6">{children}</p>
			<div className="flex justify-center">
				<Link to={url}>
					<SecondaryButton>{buttonText}</SecondaryButton>
				</Link>
			</div>
		</div>
	)
}
