import {useEffect, useState} from 'react'

import ChooseWalletModal from '../Modal/ChooseWalletModal'
import Modal from '../Modal/Modal'
import {allowedNetworksIds} from '../../constants'
import clsx from 'clsx'
import {useEthers} from '@usedapp/core'
import useWeb3Connectors from '../../hooks/useWeb3Connectors'

export default function ConnectButton({className}: {className?: string}) {
	const {account, chainId} = useEthers()
	const {activate} = useWeb3Connectors()
	const [isOpened, setIsOpened] = useState(false)
	const isCorrectNetwork = !chainId || allowedNetworksIds.includes(chainId)

	useEffect(() => {
		if (account) {
			setIsOpened(false)
		}
	}, [account, setIsOpened])

	return (
		<>
			<Modal isOpened={isOpened} setIsOpened={setIsOpened}>
				<ChooseWalletModal activate={activate} />
			</Modal>
			<button
				type="button"
				className={clsx(
					'rounded-md',
					!isCorrectNetwork || (isCorrectNetwork && !account)
						? 'glow-hover-yellow py-2 px-4 bg-gradient-to-br from-yellow-400 to-yellow-600 transition-all ease-in-out duration-300 text-white font-bold text-outline'
						: 'text-yellow-400 px-16 py-5 sm:px-3 sm:py-0.5 sm:bg-gray-800 font-medium',
					className
				)}
				disabled={Boolean(account)}
				onClick={() => setIsOpened(true)}
			>
				{(() => {
					if (!isCorrectNetwork) {
						return <span>Wrong network! Switch to Gnosis</span>
					}

					if (account) {
						return (
							<span>{account.slice(0, 6) + '...' + account.slice(-4)}</span>
						)
					}

					return <span>Connect Wallet</span>
				})()}
			</button>
		</>
	)
}
