import {EnableAsCollateralModal} from '../Modal/EnableAsCollateralModal'
import Modal from '../Modal/Modal'
import Toggle from '../Toggle/Toggle'
import {UserMarket} from '../../hooks/useMarkets'
import clsx from 'clsx'
import formatCashValue from '../../utils/formatCashValue'
import useCollateral from '../../hooks/useCollateral'
import {useState} from 'react'

export function ActiveTableMarketRow({
	market,
	index,
	hasToggle,
	marketModal,
}: {
	market: UserMarket
	index: number
	hasToggle?: boolean
	marketModal?: JSX.Element
}) {
	const [isOpened, setIsOpened] = useState(false)
	const [isEnableCollateralModalOpen, setEnableCollateralModalOpen] =
		useState(false)
	const {isEnabledAsCollateral} = useCollateral(market)

	function handleToggle(event: React.MouseEvent) {
		event.stopPropagation()
		setEnableCollateralModalOpen(true)
	}

	return (
		<>
			<Modal isOpened={isOpened} setIsOpened={setIsOpened}>
				{marketModal}
			</Modal>
			<Modal
				isOpened={isEnableCollateralModalOpen}
				setIsOpened={setEnableCollateralModalOpen}
			>
				<EnableAsCollateralModal
					market={market}
					onClose={() => setEnableCollateralModalOpen(false)}
				/>
			</Modal>
			<tr
				key={market.id}
				className={clsx(
					'hover-brighten hover:bg-gray-900 hover:bg-opacity-20 cursor-pointer',
					index % 2 !== 0 && 'bg-gray-900 bg-opacity-50'
				)}
				onClick={() => marketModal && setIsOpened(true)}
			>
				<td className="px-5 py-3 flex gap-3 items-center">
					<img
						className="h-7 w-7 rounded-full"
						src={market.asset.logoURI}
						alt=""
					/>
					<span className="truncate">{market.asset.name}</span>
				</td>
				{market.asset.isBorrowable ? (
					<td className="px-5 py-3 text-right whitespace-nowrap">
						{market.apy} %
					</td>
				) : (
					<td className="px-5 py-3 text-center whitespace-nowrap opacity-30">
						-
					</td>
				)}
				<td className="px-5 py-3 text-right">
					{formatCashValue(market.balance.rounded)}
				</td>
				{hasToggle && (
					<td className="px-5 pt-2 text-center">
						<Toggle on={isEnabledAsCollateral} onClick={handleToggle} />
					</td>
				)}
			</tr>
		</>
	)
}

export function InactiveTableMarketRow({
	market,
	index,
	hasToggle,
	marketModal,
}: {
	market: any
	index: number
	hasToggle?: boolean
	marketModal?: JSX.Element
}) {
	const [isOpened, setIsOpened] = useState(false)
	const [isEnableCollateralModalOpen, setEnableCollateralModalOpen] =
		useState(false)
	const {isEnabledAsCollateral} = useCollateral(market)

	function handleToggle(event: React.MouseEvent) {
		event.stopPropagation()
		setEnableCollateralModalOpen(true)
	}

	return (
		<>
			<Modal isOpened={isOpened} setIsOpened={setIsOpened}>
				{marketModal}
			</Modal>
			<Modal
				isOpened={isEnableCollateralModalOpen}
				setIsOpened={setEnableCollateralModalOpen}
			>
				<EnableAsCollateralModal
					market={market}
					onClose={() => setEnableCollateralModalOpen(false)}
				/>
			</Modal>
			<tr
				key={market.id}
				className={clsx(
					'hover-brighten hover:bg-gray-900 hover:bg-opacity-20 cursor-pointer',
					index % 2 !== 0 && 'bg-gray-900 bg-opacity-50'
				)}
				onClick={() => marketModal && setIsOpened(true)}
			>
				<td className="px-5 py-3 flex gap-3 items-center">
					<img
						className="h-7 w-7 rounded-full"
						src={market.asset.logoURI}
						alt=""
					/>
					<span className="truncate">{market.asset.name}</span>
				</td>
				{market.asset.isBorrowable ? (
					<td className="px-5 py-3 text-right whitespace-nowrap">
						{market.apy} %
					</td>
				) : (
					<td className="px-5 py-3 text-center whitespace-nowrap opacity-30">
						-
					</td>
				)}
				<td className="px-5 py-3 text-right">
					{formatCashValue(market.walletBalance.rounded)}
				</td>
				{hasToggle && (
					<td className="px-5 pt-2 text-center">
						<Toggle on={isEnabledAsCollateral} onClick={handleToggle} />
					</td>
				)}
			</tr>
		</>
	)
}
