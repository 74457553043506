import clsx from 'clsx'

export default function Divider({className}: {className?: string}) {
	return (
		<svg viewBox="0 0 1428 100" fill="none" className={clsx(className)}>
			<g clip-path="url(#clip0_801_802)">
				<path
					opacity="0.2"
					d="M-2 0C88.7283 0.713483 145.913 20.9138 289.91 46.0861C385.908 62.8676 541.605 68.7191 757 63.6405C467.336 120.196 214.336 118.206 -2 57.6717"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M98 80.5449C275.413 55.565 424.148 40.4035 544.204 35.0605C664.259 29.7174 808.525 32.1522 977 42.3647C929.07 43.1711 808.303 57.5734 614.7 85.5716C421.097 113.57 248.863 111.894 98 80.5449Z"
					fill="black"
				/>
				<path
					opacity="0.4"
					d="M1044 39.7325C1128.83 22.5607 1277.08 13.5446 1437 30.8968V92.3078C1269.17 59.9567 1138.17 42.4316 1044 39.7325Z"
					fill="black"
				/>
				<path
					d="M-3.54297 50.7962C53.086 65.537 94.208 75.2378 119.822 79.8962C177.454 90.3808 230.295 94.0693 268.033 96.507C307.355 99.0455 392.635 98.3085 457.025 95.1255C482.76 93.8539 514.727 91.0555 552.926 86.7324C591.747 81.997 618.372 78.4678 632.799 76.147C659.913 71.7878 708.501 62.6947 723.605 60.0985C776.47 51.0131 814.839 41.9478 852.324 36.8493C918.689 27.8224 951.676 26.5554 1007.18 24.9478C1056.7 25.7516 1093.39 27.0224 1117.24 28.7593C1157.7 31.707 1204.62 38.3239 1231.4 41.7724C1281.85 48.2678 1350.35 61.1016 1436.89 80.2724L1437.19 104.886L-2.87897 104.639L-3.54297 50.7962Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_801_802">
					<rect width="1428" height="100" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
