import clsx from 'clsx'

export default function Pill({
	value,
	selected,
	onClick,
}: {
	value: string
	selected?: boolean
	onClick?: React.MouseEventHandler<HTMLLIElement>
}) {
	return (
		<li
			className={clsx(
				'border-2 border-transparent bg-gray-900 rounded-full px-5 py-1 cursor-pointer hover-brighten transition-all hover:scale-105',
				selected
					? 'border-yellow-500 scale-105 brighten'
					: 'hover:border-gray-700'
			)}
			onClick={onClick}
		>
			{value}
		</li>
	)
}
