import {UserVault} from '../../hooks/useVaults'
import clsx from 'clsx'
import Modal from '../Modal/Modal'
import {useState} from 'react'
import {useChainState, useEthers} from '@usedapp/core'
import {useDollarPrice} from '../../hooks/useDollarPrice'
import {roundBigNumber} from '../../utils/bigNumber'
import {BigNumber} from 'ethers'

export function ActiveTableVaultRow({
	vault,
	index,
	vaultModal,
}: {
	vault: UserVault
	index: number
	hasToggle?: boolean
	vaultModal?: JSX.Element
}) {
	const [isOpened, setIsOpened] = useState(false)
	const {chainId} = useEthers()
	const price = useDollarPrice(chainId!, vault.asset.address)

	const balance =
		vault.balance.rounded < 0.0001 ? '< 0.0001' : vault.balance.rounded
	const dollarValue = vault.balance.bn
		.mul(price)
		.div(BigNumber.from(10).pow(18))
	const roundedDollarValue = roundBigNumber(dollarValue, vault.decimals)
	const displayDollarValue =
		dollarValue.gt(0) && roundedDollarValue < 0.0001
			? '< 0.0001'
			: roundedDollarValue

	return (
		<>
			<Modal isOpened={isOpened} setIsOpened={setIsOpened}>
				{vaultModal}
			</Modal>
			<tr
				className={clsx(
					'hover-brighten hover:bg-gray-900 hover:bg-opacity-20 cursor-pointer',
					index % 2 !== 0 && 'bg-gray-900 bg-opacity-50'
				)}
				onClick={() => vaultModal && setIsOpened(true)}
			>
				<td
					title={vault.asset.name}
					className="px-5 py-3 flex gap-3 items-center"
				>
					<img
						className="h-7 w-7 rounded-full"
						src={vault.asset.iconUrl}
						alt=""
					/>
					<span className="truncate">{vault.name}</span>
				</td>
				<td className="px-5 py-3 text-right whitespace-nowrap">
					{vault.apy} %
				</td>
				<td className="px-5 py-3 text-right">
					{balance} {vault.asset.symbol} / ${displayDollarValue}
				</td>
			</tr>
		</>
	)
}

export function InactiveTableVaultRow({
	vault,
	index,
	vaultModal,
}: {
	vault: UserVault
	index: number
	hasToggle?: boolean
	vaultModal?: JSX.Element
}) {
	const {account, chainId} = useEthers()
	const [isOpened, setIsOpened] = useState(false)

	const price = useDollarPrice(chainId!, vault.asset.address)

	const balance = account ? vault.walletBalance : vault.totalAssets
	const displayBalance =
		balance.bn.gt(0) && balance.rounded < 0.0001 ? '< 0.0001' : balance.rounded
	const dollarValue = balance.bn.mul(price).div(BigNumber.from(10).pow(18))
	const roundedDollarValue = roundBigNumber(dollarValue, vault.decimals)
	const displayDollarValue =
		dollarValue.gt(0) && roundedDollarValue < 0.0001
			? '< 0.0001'
			: roundedDollarValue

	return (
		<>
			<Modal isOpened={isOpened} setIsOpened={setIsOpened}>
				{vaultModal}
			</Modal>
			<tr
				key={vault.id}
				className={clsx(
					'hover-brighten hover:bg-gray-900 hover:bg-opacity-20 cursor-pointer',
					index % 2 !== 0 && 'bg-gray-900 bg-opacity-50'
				)}
				onClick={() => vaultModal && setIsOpened(true)}
			>
				<td
					title={vault.asset.name}
					className="px-5 py-3 flex gap-3 items-center"
				>
					<img
						className="h-7 w-7 rounded-full"
						src={vault.asset.iconUrl}
						alt=""
					/>
					<span className="truncate">{vault.name}</span>
				</td>
				<td className="px-5 py-3 text-right whitespace-nowrap">
					{vault.apy} %
				</td>
				<td className="px-5 py-3 text-right">
					{displayBalance} {vault.asset.symbol} / ${displayDollarValue}
				</td>
			</tr>
		</>
	)
}
