import Container from '../../components/Container/Container'
import useMarkets from '../../hooks/useMarkets'
import Table from '../../components/Table/Table'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import Divider from '../../components/Divider/Divider'
import useColumnSort from '../../hooks/useColumnSort'
import {SupplyMarketModal} from '../../components/Modal/SupplyMarketModal'
import {BorrowMarketModal} from '../../components/Modal/BorrowMarketModal'
import Pill from '../../components/Pill/Pill'
import {MarketPoolProvider} from '../../hooks/useCurrentMarketPool'
import usePoolSwitcher from '../../hooks/usePoolSwitcher'
import MarketHeader from '../../components/MarketHeader/MarketHeader'
import {
	ActiveTableMarketRow,
	InactiveTableMarketRow,
} from '../../components/Table/MarketRows'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import Modal from '../../components/Modal/Modal'
import {useEffect, useState} from 'react'
import UnderConstructionModal from '../../components/Modal/UnderConstructionModal'

export default function LandingPage() {
	const showLandingPage = useFeatureFlag('lending')
	const [open, setOpen] = useState<boolean>(false)

	const {poolId, setPoolId, pools} = usePoolSwitcher()

	useEffect(() => {
		if (!showLandingPage) setOpen(true)
	}, [showLandingPage])

	return (
		<PageTemplate>
			<Modal isOpened={open} setIsOpened={setOpen} closable={false}>
				<UnderConstructionModal url="/vaults" buttonText="Go to Vaults">
					We&apos;re working on the lending markets page.
					<br />
					In the meantime, you can visit our Vaults.
				</UnderConstructionModal>
			</Modal>
			<MarketPoolProvider poolId={poolId}>
				<div className="bg-gradient-to-r from-gray-900 to-gray-900 via-black -mt-24 pt-24">
					{pools.length > 1 && (
						<Container className="pb-4 flex gap-8 justify-center items-center">
							<h3 className="text-3xl">Markets</h3>
							<ul className="flex flex-wrap gap-4 justify-center">
								{pools.map(pool => (
									<Pill
										key={pool.id}
										value={pool.name}
										selected={pool.id === poolId}
										onClick={() => setPoolId(pool.id)}
									/>
								))}
							</ul>
						</Container>
					)}
					<MarketHeader />
					<Divider />
				</div>

				{/* Gauge:
				<div className="flex-shrink-0 text-center w-72">
					<h3 className="text-xl mb-6 font-semibold">Your Borrow Limit</h3>
					<BorrowLimit />
					<h3 className="text-xl mb-4 text-gray-500 -mt-5 relative whitespace-nowrap">
						$ 7,265.64
					</h3>
				</div> */}

				<Container cols2>
					<div>
						<h3 className="text-3xl text-center mb-8">Supply Markets</h3>
						<SupplyTables />
					</div>
					<div>
						<h3 className="text-3xl text-center mb-8">Borrow Markets</h3>
						<BorrowTable />
					</div>
				</Container>
			</MarketPoolProvider>
		</PageTemplate>
	)
}

// For 'Gauge' above
// function BorrowLimit() {
// 	const borrowLimit = 0.94
// 	const turn = borrowLimit / 2
// 	return (
// 		<div className="relative w-full mx-auto" style={{maxWidth: '250px'}}>
// 			<div
// 				className="relative h-0 w-full bg-gray-700 overflow-hidden"
// 				style={{
// 					paddingBottom: '50%',
// 					borderTopLeftRadius: '100% 200%',
// 					borderTopRightRadius: '100% 200%',
// 				}}
// 			>
// 				<div
// 					className="absolute top-full left-0 h-full bg-red-600"
// 					style={{
// 						width: 'inherit',
// 						transformOrigin: 'center top',
// 						transform:
// 							'rotate(' + (turn >= 0.5 ? '0.5' : turn.toString()) + 'turn)',
// 						transition: 'transform 0.2s ease-out',
// 					}}
// 				></div>
// 				<div
// 					className="absolute top-full left-0 h-full bg-yellow-400"
// 					style={{
// 						width: 'inherit',
// 						transformOrigin: 'center top',
// 						transform:
// 							'rotate(' + (turn >= 0.45 ? '0.45' : turn.toString()) + 'turn)',
// 						transition: 'transform 0.2s ease-out',
// 					}}
// 				></div>
// 				<div
// 					className="absolute top-full left-0 h-full bg-green-600"
// 					style={{
// 						width: 'inherit',
// 						transformOrigin: 'center top',
// 						transform:
// 							'rotate(' + (turn >= 0.4 ? '0.4' : turn.toString()) + 'turn)',
// 						transition: 'transform 0.2s ease-out',
// 					}}
// 				></div>
// 				<div
// 					className="borrow-limit absolute top-1/4 left-1/2 w-3/4 transform -translate-x-1/2 bg-black flex items-center justify-center box-border rounded-full text-3xl"
// 					style={{
// 						height: '150%',
// 						paddingBottom: '25%',
// 					}}
// 				>
// 					<div className="-mt-8 whitespace-nowrap">
// 						{Math.round(borrowLimit * 100)} %
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	)
// }

function SupplyTables() {
	const [activeMarketTableSortingOrder, changeActiveMarketTableSortingOrder] =
		useColumnSort()
	const [
		inactiveMarketTableSortingOrder,
		changeInactiveMarketTableSortingOrder,
	] = useColumnSort()
	const activeSupplyMarkets = useMarkets({
		type: 'supply',
		state: 'active',
		sortByBalanceOrder: activeMarketTableSortingOrder,
	})
	const inactiveSupplyMarkets = useMarkets({
		type: 'supply',
		state: 'inactive',
		sortByBalanceOrder: inactiveMarketTableSortingOrder,
	})
	return (
		<>
			<Table
				colNames={['Asset', 'APY', 'Balance', 'Collateral']}
				sortableCols={[2]}
				sortFunctions={{2: changeActiveMarketTableSortingOrder}}
				hasData={activeSupplyMarkets.length > 0}
			>
				{activeSupplyMarkets.map((market, index) => (
					<ActiveTableMarketRow
						key={market.asset.name}
						market={market}
						index={index}
						marketModal={<SupplyMarketModal market={market} />}
						hasToggle
					/>
				))}
			</Table>

			<Table
				colNames={['Asset', 'APY', 'Wallet', 'Collateral']}
				sortableCols={[2]}
				sortFunctions={{2: changeInactiveMarketTableSortingOrder}}
				hasData={inactiveSupplyMarkets.length > 0}
			>
				{inactiveSupplyMarkets.map((market, index) => (
					<InactiveTableMarketRow
						key={market.asset.name}
						market={market}
						index={index}
						marketModal={<SupplyMarketModal market={market} />}
						hasToggle
					/>
				))}
			</Table>
		</>
	)
}

function BorrowTable() {
	const [activeMarketTableSortingOrder, changeActiveMarketTableSortingOrder] =
		useColumnSort()
	const [
		inactiveMarketTableSortingOrder,
		changeInactiveMarketTableSortingOrder,
	] = useColumnSort()
	const activeBorrowMarkets = useMarkets({
		type: 'borrow',
		state: 'active',
		sortByBalanceOrder: activeMarketTableSortingOrder,
	})
	const inactiveBorrowMarkets = useMarkets({
		type: 'borrow',
		state: 'inactive',
		sortByBalanceOrder: inactiveMarketTableSortingOrder,
	})
	return (
		<>
			<Table
				colNames={['Asset', 'APY', 'Balance']}
				sortableCols={[2]}
				sortFunctions={{2: changeActiveMarketTableSortingOrder}}
				hasData={activeBorrowMarkets.length > 0}
			>
				{activeBorrowMarkets.map((market, index) => (
					<ActiveTableMarketRow
						key={market.asset.name}
						market={market}
						index={index}
						marketModal={<BorrowMarketModal market={market} />}
					/>
				))}
			</Table>

			<Table
				colNames={['Asset', 'APY', 'Wallet']}
				sortableCols={[2]}
				sortFunctions={{2: changeInactiveMarketTableSortingOrder}}
				hasData={inactiveBorrowMarkets.length > 0}
			>
				{inactiveBorrowMarkets.map((market, index) => (
					<InactiveTableMarketRow
						key={market.asset.name}
						market={market}
						index={index}
						marketModal={<BorrowMarketModal market={market} />}
					/>
				))}
			</Table>
		</>
	)
}
