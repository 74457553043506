import {
	TransactionStatus,
	useCall,
	useEthers,
	useSendTransaction,
	useTokenBalance,
} from '@usedapp/core'

import {BigNumber, Contract} from 'ethers'
import ERC4626RouterAbi from '../abis/ERC4626Router.abi.json'
import VaultAbi from '../abis/Vault.abi.json'
import {Interface} from 'ethers/lib/utils'
import {UserVault} from './useVaults'
import useConstants from './useConstants'
import transformBigNumber, {UsableBigNumber} from '../utils/bigNumber'
import {useMemo} from 'react'

const ERC4626RouterInterface = new Interface(ERC4626RouterAbi)
const VaultInterface = new Interface(VaultAbi)

export default function useSingleVault(vault: UserVault): {
	deposit: (amount: BigNumber) => Promise<void>
	withdraw: (amount: BigNumber, max?: boolean) => Promise<void>
	txState: TransactionStatus
	maxWithdraw: UsableBigNumber
} {
	const {address: vaultAddress, decimals, asset} = vault

	const constants = useConstants()
	const {account} = useEthers()
	const {sendTransaction, state: txState} = useSendTransaction()
	const vaultBalance = useTokenBalance(vaultAddress, account)

	const callRes = useCall(
		vaultBalance && {
			contract: new Contract(vaultAddress, VaultInterface),
			method: 'previewRedeem',
			args: [vaultBalance],
		}
	)

	if (callRes?.error) console.error(callRes.error)

	const maxWithdraw = useMemo(
		() =>
			transformBigNumber(
				callRes ? callRes?.value?.[0] : BigNumber.from(0),
				decimals
			),
		[callRes, decimals]
	)

	async function deposit(value: BigNumber) {
		if (asset.isWrappedNative) {
			const calls = [
				ERC4626RouterInterface.encodeFunctionData('wrapWETH9', []),
				ERC4626RouterInterface.encodeFunctionData('approve', [
					asset.address,
					vaultAddress,
					BigNumber.from(2).pow(256).sub(1),
				]),
				ERC4626RouterInterface.encodeFunctionData('deposit', [
					vaultAddress,
					account,
					value,
					1,
				]),
			]

			await sendTransaction({
				chainId: constants.chainId,
				to: constants.erc4626Router,
				value,
				data: ERC4626RouterInterface.encodeFunctionData('multicall', [calls]),
			})
		} else {
			const calls = [
				ERC4626RouterInterface.encodeFunctionData('approve', [
					asset.address,
					vaultAddress,
					BigNumber.from(2).pow(256).sub(1),
				]),
				ERC4626RouterInterface.encodeFunctionData('depositToVault', [
					vaultAddress,
					account,
					value,
					1,
				]),
			]

			await sendTransaction({
				chainId: constants.chainId,
				to: constants.erc4626Router,
				data: ERC4626RouterInterface.encodeFunctionData('multicall', [calls]),
			})
		}
	}

	async function withdraw(value: BigNumber, max?: boolean) {
		// When withdrawing max native we need to account for changes on the chain and set minAmount as 99% of the maxWithdraw to have 1% slippage
		const ninetyNine = BigNumber.from(99).mul(BigNumber.from(10).pow(16))
		const maxMinAmount = value.mul(ninetyNine).div(BigNumber.from(10).pow(18))

		if (asset.isWrappedNative) {
			const calls = [
				max
					? ERC4626RouterInterface.encodeFunctionData('redeemMax', [
							vaultAddress,
							constants.erc4626Router,
							1,
					  ])
					: ERC4626RouterInterface.encodeFunctionData('withdraw', [
							vaultAddress,
							constants.erc4626Router,
							value,
							BigNumber.from(2).pow(256).sub(1),
					  ]),
				ERC4626RouterInterface.encodeFunctionData('unwrapWETH9', [
					max ? maxMinAmount : value,
					account,
				]),
			]

			await sendTransaction({
				chainId: constants.chainId,
				to: constants.erc4626Router,
				data: ERC4626RouterInterface.encodeFunctionData('multicall', [calls]),
			})
		} else {
			const data = max
				? ERC4626RouterInterface.encodeFunctionData('redeemMax', [
						vaultAddress,
						account,
						1,
				  ])
				: ERC4626RouterInterface.encodeFunctionData('withdraw', [
						vaultAddress,
						account,
						value,
						BigNumber.from(2).pow(256).sub(1),
				  ])

			await sendTransaction({
				chainId: constants.chainId,
				to: constants.erc4626Router,
				data,
			})
		}
	}

	return {
		deposit,
		withdraw,
		txState,
		maxWithdraw,
	}
}
