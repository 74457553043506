import {ChainId, useEthers} from '@usedapp/core'
import {useCallback, useEffect} from 'react'

import {InjectedConnector} from '@web3-react/injected-connector'
import {RPCs} from '../constants'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'
import WalletConnectProvider from '@walletconnect/ethereum-provider'
import WalletLink from 'walletlink'
import {WalletLinkConnector} from '@web3-react/walletlink-connector'

const injected = new InjectedConnector({
	supportedChainIds: [3, 4, 100],
})

const walletLink = new WalletLinkConnector({
	url: RPCs[ChainId.Rinkeby],
	supportedChainIds: [3, 4, 100],
	appName: 'Citrus',
	darkMode: true,
})

// hack to monitor if WalletLink is connected
const walletLinkProvider = new WalletLink({
	appName: 'Citrus',
	darkMode: true,
}).makeWeb3Provider(RPCs[ChainId.Rinkeby])

const walletConnect = new WalletConnectConnector({
	rpc: RPCs,
	bridge: 'https://bridge.walletconnect.org',
})

// hack to monitor if WalletConnect is connected
const walletConnectProvider = new WalletConnectProvider({
	rpc: RPCs,
	bridge: 'https://bridge.walletconnect.org',
})

export default function useWeb3Connectors() {
	const {account, chainId, activate: ethersActivate} = useEthers()

	function setInjectedProvider(providerName: 'metaMask' | 'walletLink') {
		// providers do not exist on ethereum
		// @ts-ignore
		const providers = window.ethereum?.providers
		if (providers) {
			const injectedProvider = providers.find((provider: any) => {
				switch (providerName) {
					case 'metaMask':
						return provider.isMetaMask
					case 'walletLink':
						return provider.isWalletLink
					default:
						return false
				}
			})
			// selectedProvider does not exist on ethereum
			// @ts-ignore
			window.ethereum.selectedProvider = injectedProvider
		}
	}

	const getAuthorizeConnector = useCallback(async () => {
		setInjectedProvider('metaMask')
		const isMetamaskAuthorized = await injected.isAuthorized()

		if (isMetamaskAuthorized) {
			return injected
		}

		setInjectedProvider('walletLink')
		const isWalletLinkAuthorized = await injected.isAuthorized()

		if (isWalletLinkAuthorized || walletLinkProvider.selectedAddress) {
			return walletLink
		}

		if (walletConnectProvider.connected) {
			return walletConnect
		}

		return null
	}, [])

	useEffect(() => {
		;(async () => {
			const authorizedConnector = await getAuthorizeConnector()
			if (authorizedConnector && !account && !!chainId) {
				ethersActivate(authorizedConnector)
			}
		})()
	}, [getAuthorizeConnector, account, chainId, ethersActivate])

	function activateConnector(
		connectorName: 'metaMask' | 'walletLink' | 'walletConnect'
	) {
		let connector:
			| InjectedConnector
			| WalletLinkConnector
			| WalletConnectConnector = injected
		switch (connectorName) {
			case 'metaMask':
				setInjectedProvider('metaMask')
				connector = injected
				break
			case 'walletLink':
				setInjectedProvider('walletLink')
				connector = walletLink
				break
			case 'walletConnect':
				connector = walletConnect
				break
		}

		ethersActivate(connector)
	}

	return {activate: activateConnector}
}
