import {useEffect, useState} from 'react'

import {useSearchParams} from 'react-router-dom'

type FeatureFlag = 'token' | 'lending'

export default function useFeatureFlag(flag: FeatureFlag): boolean {
	const [isOn, setFlag] = useState(process.env.NODE_ENV === 'development')
	const [searchParams] = useSearchParams()

	const featureSearchParam = searchParams.get('feature')

	useEffect(() => {
		if (featureSearchParam?.split(',').includes(flag)) {
			setFlag(true)
		}
	}, [featureSearchParam, flag])

	return isOn
}
