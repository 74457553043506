import {ReactNode} from 'react'
import clsx from 'clsx'

interface ToggleProps {
	label?: ReactNode
	on: boolean
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function Toggle({label, on: isOn, onClick}: ToggleProps) {
	return (
		<>
			<button
				onClick={onClick}
				type="button"
				className="group inline-flex relative flex-shrink-0 justify-center items-center w-10 h-5 rounded-full cursor-pointer focus:outline-none"
				aria-pressed={isOn}
			>
				<span
					aria-hidden="true"
					className={clsx(
						'absolute w-full h-2 rounded-xl pointer-events-none',
						isOn ? 'bg-green-600' : 'bg-gray-800'
					)}
				/>
				<span
					aria-hidden="true"
					className={clsx(
						'inline-block absolute left-0 w-4 h-4 bg-gradient-to-b from-gray-600 to-gray-700 rounded-full ring-0 shadow transition-transform duration-200 transform pointer-events-none',
						isOn ? 'translate-x-6' : 'translate-x-0'
					)}
				/>
			</button>
			<div className={clsx(isOn && 'text-green')}>{label}</div>
		</>
	)
}
