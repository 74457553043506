import {useState} from 'react'
import useConstants from './useConstants'
import useFeatureFlag from './useFeatureFlag'

export default function usePoolSwitcher(): {
	poolId: string
	setPoolId: (poolId: string) => void
	pools: {
		id: string
		name: string
	}[]
} {
	const constants = useConstants()
	const [poolId, setPoolId] = useState('primary')

	return {
		poolId,
		setPoolId,
		pools: constants.marketPools.map(p => ({
			id: p.id,
			name: p.name,
		})),
	}
}
