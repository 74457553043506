import clsx from 'clsx'
import {useState} from 'react'
import {ClaimModal} from '../Modal/ClaimModal'
import Modal from '../Modal/Modal'

export default function ClaimButton({className}: {className?: string}) {
	const [open, setOpen] = useState<boolean>(false)
	return (
		<>
			<Modal isOpened={open} setIsOpened={setOpen}>
				<ClaimModal />
			</Modal>
			<div
				className={clsx(
					'inline-block px-16 sm:px-5 py-5 sm:py-3 w-full font-medium text-yellow-400 cursor-pointer',
					className
				)}
				onClick={() => setOpen(true)}
			>
				Claim
			</div>
		</>
	)
}
