import {ArrowsSortIcon} from '../Icon'
import React from 'react'
import clsx from 'clsx'

export default function Table({
	colNames,
	sortableCols,
	hasData,
	children,
	sortFunctions,
}: {
	colNames: Array<string>
	// indexes of columns that should be sortable
	sortableCols?: Array<number>
	hasData: boolean
	children: React.ReactNode
	sortFunctions?: {[key: number]: () => void}
}) {
	if (!hasData) {
		return null
	}

	return (
		<div className="block w-full overflow-auto mb-8">
			<table className="w-full rounded-xl overflow-hidden">
				<thead className="text-white uppercase tracking-widest bg-gray-900 text-xs">
					<tr>
						{colNames &&
							colNames.map(function (value, i) {
								return (
									<th
										key={value + i}
										className={clsx(
											'px-5 py-4 font-normal',
											i === 0 ? 'text-left' : 'text-right',
											sortableCols &&
												sortableCols.includes(i) &&
												'flex items-center gap-1 justify-end'
										)}
									>
										{value}
										{sortableCols && sortableCols.includes(i) && (
											<span
												onClick={sortFunctions![i]}
												className="cursor-pointer hover:bg-gray-700 transition-all rounded-full p-1 text-yellow-400 active:bg-gray-800"
											>
												<ArrowsSortIcon size="tiny" />
											</span>
										)}
									</th>
								)
							})}
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-900">{children}</tbody>
			</table>
		</div>
	)
}
