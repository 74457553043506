import {BigNumber, Contract} from 'ethers'
import {useCall, useEtherBalance, useEthers} from '@usedapp/core'

import {Interface} from 'ethers/lib/utils'
import {VaultAsset} from '../types/Vault'
import VaultLensAbi from '../abis/VaultLens.abi.json'
import useConstants from './useConstants'
import transformBigNumber, {UsableBigNumber} from '../utils/bigNumber'
import sortMarketsOrVaults from '../utils/sortMarketsOrVaults'

export type UserVault = {
	id: string
	name: string
	address: string
	routerAddress: string
	asset: VaultAsset & {address: string}
	withdrawalFee: UsableBigNumber
	harvestFee: UsableBigNumber
	apy: number
	totalAssets: UsableBigNumber
	decimals: number
	balance: UsableBigNumber
	walletBalance: UsableBigNumber
}

interface VaultCoreData {
	vault: string
	asset: string
	apy: BigNumber
	decimals: number
	withdrawalFee: BigNumber
	harvestFee: BigNumber
}

type UserVaultMetadata = VaultCoreData & {
	balance: BigNumber
	assetBalance: BigNumber
}

type VaultMetadata = VaultCoreData & {
	totalAssets: BigNumber
	harvestable: {
		token: string
		amount: BigNumber
	}
}

const VaultLensInterface = new Interface(VaultLensAbi)

export default function useVaults(
	sortingOrder: 'asc' | 'desc',
	state?: 'active' | 'inactive'
): UserVault[] {
	const {account} = useEthers()
	const constants = useConstants()
	const xdaiBalance = useEtherBalance(account)

	const vaultsAddresses = constants.vaults?.map(vault => vault.address)

	const vaults: UserVault[] = []

	const call =
		constants.vaultLens &&
		constants.vaultLens !== 'TODO' &&
		(account
			? {
					contract: new Contract(constants.vaultLens, VaultLensInterface),
					method: 'getUserVaultsMetadata',
					args: [account, vaultsAddresses],
			  }
			: {
					contract: new Contract(constants.vaultLens, VaultLensInterface),
					method: 'getVaultsMetadata',
					args: [vaultsAddresses],
			  })

	const getUserVaultsMetadataCallRes = useCall(call)

	if (getUserVaultsMetadataCallRes?.error)
		console.error(getUserVaultsMetadataCallRes?.error)

	const vaultMetadata: UserVaultMetadata[] | VaultMetadata[] =
		getUserVaultsMetadataCallRes?.value[0]

	if (constants && vaultMetadata) {
		constants.vaults.forEach((vault, i) => {
			const decimals = vaultMetadata[i]?.decimals

			vaults.push({
				id: `${vault.asset.symbol}-vault`,
				name: vault.name,
				address: vault.address,
				routerAddress: constants.erc4626Router,
				asset: {...vault.asset, address: vaultMetadata[i].asset},
				apy: transformBigNumber(vaultMetadata[i]?.apy, 18, 2).rounded,
				withdrawalFee: transformBigNumber(vaultMetadata[i]?.withdrawalFee, 18),
				harvestFee: transformBigNumber(vaultMetadata[i]?.harvestFee, 18),
				totalAssets: transformBigNumber(
					account
						? BigNumber.from(0)
						: (vaultMetadata[i] as VaultMetadata)?.totalAssets,
					decimals
				),
				decimals,
				balance: transformBigNumber(
					account
						? (vaultMetadata[i] as UserVaultMetadata)?.balance
						: BigNumber.from(0),
					decimals
				),
				walletBalance:
					vault.asset.symbol === 'WXDAI'
						? transformBigNumber(xdaiBalance ?? BigNumber.from(0), 18)
						: transformBigNumber(
								(vaultMetadata[i] as UserVaultMetadata).assetBalance ??
									BigNumber.from(0),
								decimals
						  ),
			})
		})
	}

	return sortMarketsOrVaults(
		vaults,
		state === 'active' ? 'balance' : 'walletBalance',
		sortingOrder
	).filter(vault =>
		state === 'active' ? vault.balance.bn.gt(0) : vault.balance.bn.eq(0)
	)
}
