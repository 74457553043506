import {
	TransactionStatus,
	useContractCall,
	useSendTransaction,
} from '@usedapp/core'

import {BigNumber} from '@ethersproject/bignumber'
import ERC20Abi from '../abis/ERC20.abi.json'
import {Interface} from '@ethersproject/abi'
import useConstants from './useConstants'
import {useEthers} from '@usedapp/core'

const ERC20Contract = new Interface(ERC20Abi)

export default function useApproveContract(
	contract: string,
	asset: string,
	isNative: boolean
): [boolean, () => Promise<void>, TransactionStatus] {
	const constants = useConstants()
	const {account} = useEthers()
	const {sendTransaction, state} = useSendTransaction()

	const allowance: BigNumber = (useContractCall({
		abi: ERC20Contract,
		address: asset,
		method: 'allowance',
		args: [account, contract],
	}) ?? [BigNumber.from(0)])[0]

	const isApproved = Boolean(account) && (isNative || allowance.gt(0))

	async function approveContract() {
		await sendTransaction({
			chainId: constants.chainId,
			to: asset,
			data: ERC20Contract.encodeFunctionData('approve', [
				contract,
				BigNumber.from(2).pow(256).sub(1),
			]),
		})
	}

	return [isApproved, approveContract, state]
}
