import React from 'react'
import clsx from 'clsx'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'

export default function PageTemplate({
	children,
	className,
}: {
	children: React.ReactNode
	className?: string
}) {
	return (
		<div className={clsx('bg-black text-white font-sans', className)}>
			<NavBar />
			<div className="relative min-h-screen pb-20 pt-24">
				{children}
				<Footer />
			</div>
		</div>
	)
}
