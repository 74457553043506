import './index.css'

import {ChainId, DAppProvider} from '@usedapp/core'

import App from './App'
import {BrowserRouter} from 'react-router-dom'
import {RPCs} from './constants'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

const config = {
	readOnlyChainId: ChainId.xDai,
	readOnlyUrls: RPCs,
	autoConnect: false,
}

ReactDOM.render(
	<React.StrictMode>
		<DAppProvider config={config}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</DAppProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
