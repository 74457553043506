import {createContext, useContext, useMemo} from 'react'
import {NetworkConfig} from '../types/NetworkConfig'
import useConstants from './useConstants'

type Pool = NetworkConfig['marketPools'][0]

const Context = createContext<Pool | null>(null)

export default function useCurrentMarketPool(): Pool {
	const pool = useContext(Context)

	if (!pool) {
		throw new Error('Make sure your component is inside MarketPoolProvider')
	}

	return pool
}

export function MarketPoolProvider({
	poolId,
	children,
}: {
	poolId: string
	children: React.ReactNode
}) {
	const constants = useConstants()
	const pool = useMemo(
		() => constants.marketPools.find(x => x.id === poolId),
		[constants, poolId]
	) ?? {
		id: 'error',
		name: 'Error',
		markets: [],
		comptroller: '',
	}

	return <Context.Provider value={pool ?? null}>{children}</Context.Provider>
}
