import {BigNumber} from 'ethers'
import transformBigNumber, {UsableBigNumber} from './bigNumber'

export function convertToUsd(
	amount: BigNumber,
	price: BigNumber,
	cryptoDecimals: number
): UsableBigNumber {
	const bigTotal = amount.mul(price).div(BigNumber.from(10).pow(cryptoDecimals))

	return transformBigNumber(bigTotal, 18, 2)
}
