import {useMemo, useState} from 'react'

import Modal from './Modal'
import PrimaryButton from '../Button/Button'
import {SpinnerModal} from './SpinnerModal'
import {TransactionStatus} from '@usedapp/core'
import {UsableBigNumber} from '../../utils/bigNumber'
import {UserMarket} from '../../hooks/useMarkets'
import clsx from 'clsx'
import formatCashValue from '../../utils/formatCashValue'
import useAccountLiquidityCalculations from '../../hooks/useAccountLiquidityCalculations'
import useApproveContract from '../../hooks/useApproveContract'
import useMoveAssets from '../../hooks/useMoveAssets'

type TransactionTypes = 'enable' | 'borrow' | 'repay'

export function BorrowMarketModal({market}: {market: UserMarket}) {
	const [open, setOpen] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<'Borrow' | 'Repay'>('Borrow')
	const [value, setValue] = useState<string>('')
	const [repayAll, setRepayAll] = useState(false)
	const [currentTxType, setCurrentTxType] = useState<
		TransactionTypes | undefined
	>(undefined)
	const [isEnabled, enableMarket, marketEnableTxState] = useApproveContract(
		market.cTokenAddress,
		market.underlyingAssetAddress,
		market.asset.type === 'base'
	)
	const {txState, borrowAsset, repayAsset} = useMoveAssets(market)
	const {newValues, calculate80PercentBorrowLimit} =
		useAccountLiquidityCalculations(
			market,
			value,
			activeTab.toLowerCase() as 'borrow' | 'repay'
		)

	const currentTxState = useMemo<TransactionStatus>(() => {
		switch (currentTxType) {
			case 'enable':
				return marketEnableTxState
			case 'borrow':
			case 'repay':
				return txState
			default:
				return {
					status: 'None',
				}
		}
	}, [currentTxType, txState, marketEnableTxState])

	function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (activeTab === 'Repay' && repayAll) {
			setRepayAll(false)
		}

		setValue(event.target.value)
	}

	function handleMaxButtonClick() {
		if (activeTab === 'Borrow') {
			const eightyPercent = calculate80PercentBorrowLimit()
			setValue(eightyPercent.toString())
		} else {
			const maxAmount = market.balance.rounded

			setValue(maxAmount.toString())
			setRepayAll(true)
		}
	}

	async function onConfirm() {
		setOpen(true)
		if (activeTab === 'Borrow') {
			setCurrentTxType('borrow')
			await borrowAsset(value)
		} else if (isEnabled) {
			setCurrentTxType('repay')
			await repayAsset(value, repayAll)
		} else {
			setCurrentTxType('enable')
			await enableMarket()
		}
	}

	return (
		<>
			<Modal isOpened={open} setIsOpened={setOpen}>
				<SpinnerModal
					setOpen={setOpen}
					txStatus={currentTxState.status}
					txHash={currentTxState.transaction?.hash}
					txErrorMessage={currentTxState.errorMessage}
				/>
			</Modal>
			<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full mb-4">
				<img
					className="w-full h-full rounded-full"
					src={market.asset.logoURI}
					alt={market.asset.name + ' logo'}
					aria-hidden="true"
				/>
			</div>
			<h3 className="text-xl leading-6 mb-4 font-semibold text-center">
				{market.asset.name}
			</h3>
			{(isEnabled || activeTab === 'Borrow') && (
				<>
					<div className="relative">
						<input
							autoFocus
							type="tel"
							inputMode="numeric"
							className="w-full bg-gray-900 text-3xl pl-5 pr-14 py-3 rounded-lg border border-gray-800 text-center focus:outline-none truncate"
							style={{
								boxShadow: 'inset #000000 0px 0px 25px 0px',
							}}
							value={value}
							onChange={handleInputChange}
						/>
						<button
							type="button"
							className="absolute top-1/2 -translate-y-1/2 right-3 rounded-md font-medium text-sm text-yellow-400 hover:underline transition-all"
							onClick={handleMaxButtonClick}
						>
							Max
							{activeTab === 'Borrow' && <div>(80%)</div>}
						</button>
					</div>
					<p className="mt-2 mb-4 flex gap-3 justify-center">
						<span>
							{activeTab === 'Repay'
								? 'Wallet balance:'
								: 'Currently borrowing:'}
						</span>
						<span>
							{
								market[activeTab === 'Repay' ? 'walletBalance' : 'balance']
									.rounded
							}
						</span>
					</p>
				</>
			)}
			<p className="mb-4 text-sm text-gray-500 text-center"></p>
			<Tabs
				market={market}
				setActiveTab={setActiveTab}
				isEnabled={isEnabled}
				disableConfirmButton={
					Number.isNaN(Number(value)) ||
					(activeTab === 'Repay' &&
						(Number(value) > market.walletBalance.rounded ||
							Number(value) > market.balance.rounded ||
							market.balance.rounded === 0))
				}
				onConfirmButtonClick={onConfirm}
				newValues={newValues}
			/>
		</>
	)
}

function Tabs({
	market,
	setActiveTab,
	isEnabled,
	disableConfirmButton,
	onConfirmButtonClick,
	newValues,
}: {
	market: UserMarket
	setActiveTab: React.Dispatch<React.SetStateAction<'Borrow' | 'Repay'>>
	isEnabled: boolean
	disableConfirmButton: boolean
	onConfirmButtonClick: () => Promise<void>
	newValues?: {
		totalSupplied: UsableBigNumber
		totalBorrowed: UsableBigNumber
		borrowLimit: UsableBigNumber
		borrowLimitUsed: string
	}
}) {
	const {totalBorrowed, borrowLimitUsed, borrowLimit} =
		useAccountLiquidityCalculations(market)
	const [tabs, setTabs] = useState<
		{
			name: 'Borrow' | 'Repay'
			current: boolean
		}[]
	>([
		{name: 'Borrow', current: true},
		{name: 'Repay', current: false},
	])
	const showDistributionApy = false
	const showBorrowLimit = !(
		tabs.filter(tab => tab.current)[0].name === 'Repay' && !isEnabled
	)
	const notEnoughCollateral =
		(tabs.filter(tab => tab.current)[0].name === 'Borrow' &&
			borrowLimit.bn.isZero()) ||
		newValues?.borrowLimitUsed === '100'

	return (
		<div>
			<div className="border-b border-gray-700 -mb-px flex" aria-label="Tabs">
				{tabs.map(tab => (
					<button
						key={tab.name}
						onClick={() => {
							const newTabs = tabs.map(t => ({
								...t,
								current: t.name === tab.name,
							}))
							setTabs(newTabs)
							setActiveTab(newTabs.filter(tab => tab.current)[0].name)
						}}
						className={clsx(
							'w-1/2 py-4 px-1 text-center border-b-2 font-medium',
							tab.current
								? 'border-yellow-400 text-yellow-400'
								: 'border-transparent text-gray-500 hover:text-white hover:border-gray-700'
						)}
					>
						{tab.name}
					</button>
				))}
			</div>

			<div className="pt-6 pb-7 flex flex-col gap-4 text-left">
				<p>
					<a
						href="/"
						className="text-yellow-400 hover:underline focus:outline-none"
					>
						View borrow rates
					</a>
				</p>
				<div className="flex gap-3 items-center">
					<div className="h-7 w-7 rounded-full flex-shrink-0">
						<img
							className="w-full h-full rounded-full"
							src={market.asset.logoURI}
							alt=""
							aria-hidden="true"
						/>
					</div>
					<p className="flex-1">Borrow APY</p>
					<p>{market.apy} %</p>
				</div>
				{showDistributionApy && (
					<div className="flex gap-3 items-center">
						<div className="h-7 w-7 rounded-full flex-shrink-0">
							<img
								className="w-full h-full rounded-full"
								src="https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/gnosis/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d/logo.png"
								alt=""
								aria-hidden="true"
							/>
						</div>
						<p className="flex-1">Distribution APY</p>
						<p>27.83 %</p>
					</div>
				)}
				{showBorrowLimit && (
					<>
						<div className="flex gap-3">
							<p className="flex-1">Borrow balance</p>
							<p>
								$ {formatCashValue(totalBorrowed.rounded, 2)}{' '}
								<span className="text-blue-500">&rarr;</span> ${' '}
								{newValues
									? formatCashValue(newValues.totalBorrowed.rounded, 2)
									: formatCashValue(totalBorrowed.rounded, 2)}
							</p>
						</div>

						<div className="flex gap-3">
							<p className="flex-1">Borrow limit used</p>
							<p>
								{borrowLimitUsed} %{' '}
								<span className="text-blue-500">&rarr;</span>{' '}
								{newValues ? newValues.borrowLimitUsed : borrowLimitUsed} %
							</p>
						</div>
					</>
				)}
			</div>
			<div className="flex flex-col gap-4">
				<PrimaryButton
					className="w-full"
					onClick={onConfirmButtonClick}
					disabled={disableConfirmButton || notEnoughCollateral}
				>
					{tabs.filter(tab => tab.current)[0].name === 'Borrow'
						? 'Borrow'
						: !isEnabled
						? 'Enable'
						: 'Repay'}
				</PrimaryButton>
			</div>
		</div>
	)
}
