import Container from '../../components/Container/Container'
import Divider from '../../components/Divider/Divider'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import clsx from 'clsx'
//import {useState} from 'react'
import {SecondaryButton} from '../../components/Button/Button'
import Table from '../../components/Table/Table'
import Pill from '../../components/Pill/Pill'

export default function StakePage() {
	//const [activeTab, setActiveTab] = useState<'Main' | 'Sushi' | 'Another' | 'One More'>('Main')
	return (
		<PageTemplate>
			<div className="bg-gradient-to-r from-gray-900 to-gray-900 via-black -mt-24 pt-24 dark-overlay">
				<Container className="relative lg:pt-20 pb-0 md:w-2/3">
					<h1 className="text-3xl text-center mb-8">
						Stake Your Citrus and Earn Rewards!
					</h1>
					<h3 className="text-xl mb-6">Your Staked Pools</h3>
					<Table colNames={['Asset', 'APY', 'Claim Amount', '']} hasData={true}>
						<tr
							className={clsx(
								'hover-brighten hover:bg-gray-900 hover:bg-opacity-20 cursor-pointer odd:bg-gray-900 odd:bg-opacity-50'
							)}
						>
							<td className="px-5 py-3">
								<img
									className="h-7 w-7 rounded-full float-left mr-3"
									src={''}
									alt=""
								/>
								<span className="truncate align-middle">Coin Asset Name</span>
							</td>
							<td className="px-5 py-3 whitespace-nowrap">4.32 %</td>
							<td className="px-4 py-3 text-right">
								<span className="mr-4">$573.87</span>

								<button
									type="button"
									className={clsx(
										'border-2 border-transparent bg-gray-900 rounded-full px-5 py-1 cursor-pointer hover-brighten transition-all hover:scale-105 hover:border-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed text-yellow-400'
									)}
								>
									Claim
								</button>
							</td>
							<td>
								<button
									type="button"
									className={clsx(
										'border-2 border-transparent bg-gray-900 rounded-full px-5 py-1 cursor-pointer hover-brighten transition-all hover:scale-105 hover:border-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed text-yellow-400'
									)}
								>
									Unstake
								</button>
							</td>
						</tr>
						<tr
							className={clsx(
								'hover-brighten hover:bg-gray-900 hover:bg-opacity-20 cursor-pointer odd:bg-gray-900 odd:bg-opacity-50'
							)}
						>
							<td className="px-5 py-3">
								<img
									className="h-7 w-7 rounded-full float-left mr-3"
									src={''}
									alt=""
								/>
								<span className="truncate align-middle">Coin Asset Name</span>
							</td>
							<td className="px-5 py-3 whitespace-nowrap">4.32 %</td>
							<td className="px-4 py-3 text-right">
								<button
									type="button"
									disabled
									className={clsx(
										'border-2 border-transparent bg-gray-900 rounded-full px-5 py-1 cursor-pointer hover-brighten transition-all hover:scale-105 hover:border-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed text-yellow-400'
									)}
								>
									Claim
								</button>
							</td>
							<td>
								<button
									type="button"
									className={clsx(
										'border-2 border-transparent bg-gray-900 rounded-full px-5 py-1 cursor-pointer hover-brighten transition-all hover:scale-105 hover:border-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed text-yellow-400'
									)}
								>
									Unstake
								</button>
							</td>
						</tr>
					</Table>
				</Container>
				<Divider />
			</div>

			<Container>
				{/* <Tabs
					setActiveTab={setActiveTab}
				/> */}
				<ul className="flex flex-wrap gap-3">
					<Pill value="HoneySwap" />
					<Pill value="SushiSwap" />
					<Pill value="Beefy" />
					<Pill value="Something" />
					<Pill value="Banana" />
					<Pill value="Table" />
					<Pill value="Tomato" />
					<Pill value="Distant" />
					<Pill value="Engineer" />
					<Pill value="Garage" />
					<Pill value="Dancers" />
					<Pill value="Federal" />
					<Pill value="Elections" />
					<Pill value="Account" />
					<Pill value="Pool" />
				</ul>
				<div className="relative py-6 px-7 flex flex-wrap gap-6">
					<StakeCard
						imgSrc="https://loremflickr.com/200/200"
						coinName="Ether"
						productName="Ether Product"
					/>
					<StakeCard
						imgSrc="https://loremflickr.com/240/240"
						coinName="USD Coin"
						productName="USD Coin Product"
					/>
					<StakeCard
						imgSrc="https://loremflickr.com/280/280"
						coinName="Dai"
						productName="Dai Product"
					/>
					<StakeCard
						imgSrc="https://loremflickr.com/300/300"
						coinName="Coin Name"
						productName="Product Name"
					/>
				</div>
			</Container>
		</PageTemplate>
	)
}

// function Tabs({
// 	setActiveTab,
// }: {
// 	setActiveTab: React.Dispatch<
// 		React.SetStateAction<'Main' | 'Sushi' | 'Another' | 'One More'>
// 	>
// }) {
// 	const [tabs, setTabs] = useState<
// 		{
// 			name: 'Main' | 'Sushi' | 'Another' | 'One More'
// 			current: boolean
// 		}[]
// 	>([
// 		{name: 'Main', current: true},
// 		{name: 'Sushi', current: false},
// 		{name: 'Another', current: false},
// 		{name: 'One More', current: false},
// 	])
// 	const showDistributionApy = false
// 	const showBorrowLimit = false

// 	return (
// 		<div
// 			className="relative border-b border-gray-700 -mb-px flex"
// 			aria-label="Tabs"
// 		>
// 			{tabs.map(tab => (
// 				<button
// 					key={tab.name}
// 					onClick={() => {
// 						const newTabs = tabs.map(t => ({
// 							...t,
// 							current: t.name === tab.name,
// 						}))
// 						setTabs(newTabs)
// 						setActiveTab(newTabs.filter(tab => tab.current)[0].name)
// 					}}
// 					className={clsx(
// 						'w-1/2 py-4 px-1 text-center border-b-2 font-medium',
// 						tab.current
// 							? 'border-yellow-400 text-yellow-400'
// 							: 'border-transparent text-gray-500 hover:text-white hover:border-gray-700'
// 					)}
// 				>
// 					{tab.name}
// 				</button>
// 			))}
// 		</div>
// 	)
// }

function StakeCard({
	imgSrc,
	coinName,
	productName,
}: {
	imgSrc: string
	coinName: string
	productName: string
}) {
	return (
		<div className="flex-1">
			<div className="px-8 py-8 rounded-xl bg-gray-900 mt-12 flex flex-col items-center hover:scale-105 transition-all duration-300 border-2 border-transparent hover:border-gray-700">
				<img
					src={imgSrc}
					alt={coinName}
					className="h-28 w-28 rounded-full -mt-20 mb-4 shadow"
				/>
				<h3 className="text-3xl truncate">{coinName}</h3>
				<p className="mb-4 text-gray-500">{productName}</p>
				<h3 className="text-3xl truncate">4.37%</h3>
				<p className="mb-6 text-gray-500">Approx. APY</p>
				<SecondaryButton className="w-full rounded-full">Stake</SecondaryButton>
			</div>
		</div>
	)
}
