import React, {MouseEventHandler} from 'react'
import clsx from 'clsx'

export default function PrimaryButton({
	children,
	className,
	disabled = false,
	onClick,
}: {
	children: React.ReactNode
	className?: string
	disabled?: boolean
	onClick?: MouseEventHandler
}) {
	return (
		<button
			type="button"
			disabled={disabled}
			className={clsx(
				'inline-flex justify-center rounded-md px-4 py-2 font-medium transition-all border',
				className,
				disabled
					? 'bg-gray-800 cursor-not-allowed text-gray-500 border-gray-800'
					: 'bg-gradient-to-br from-yellow-400 to-yellow-500 glow-hover-yellow border-yellow-400 text-white text-outline'
			)}
			onClick={onClick}
		>
			{children}
		</button>
	)
}

export function SecondaryButton({
	children,
	className,
	disabled = false,
	onClick,
}: {
	children: React.ReactNode
	className?: string
	disabled?: boolean
	onClick?: MouseEventHandler
}) {
	return (
		<button
			type="button"
			disabled={disabled}
			className={clsx(
				'inline-flex justify-center rounded-md px-4 py-2 font-medium transition-all border',
				className,
				disabled
					? 'bg-black cursor-not-allowed text-gray-500 border-gray-800'
					: 'bg-black border-yellow-400 text-yellow-400 glow-hover-yellow-secondary text-outline'
			)}
			onClick={onClick}
		>
			{children}
		</button>
	)
}
