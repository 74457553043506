import {Logo2} from '../Icon'
import Modal from './Modal'
import PrimaryButton from '../Button/Button'
import {SpinnerModal} from './SpinnerModal'
import formatCashValue from '../../utils/formatCashValue'
import useComp from '../../hooks/useComp'
import {useState} from 'react'

export function ClaimModal() {
	const {
		totalBalance,
		walletBalance,
		unclaimedBalance,
		compPrice,
		totalCompValueUsd,
		claimComp,
		transactionState,
	} = useComp()
	const [open, setOpen] = useState<boolean>(false)
	const disableClaimButton = unclaimedBalance.bn.isZero()

	async function claim() {
		setOpen(true)
		await claimComp()
	}

	return (
		<>
			<Modal isOpened={open} setIsOpened={setOpen}>
				<SpinnerModal
					setOpen={setOpen}
					txStatus={transactionState.status}
					txHash={transactionState.transaction?.hash}
					txErrorMessage={transactionState.errorMessage}
					claimAmount={formatCashValue(unclaimedBalance.rounded)}
				/>
			</Modal>
			<div className="mb-8">
				<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full mb-4">
					<Logo2 size="large" />
				</div>
				<div>
					<h3 className="text-xl mb-1 font-semibold text-center">
						{formatCashValue(totalBalance.rounded, 8)}
					</h3>
					<p className="mb-8 text-center">
						$ {formatCashValue(totalCompValueUsd.rounded, 2)}
					</p>
					<div className="flex flex-col gap-3">
						<div className="flex gap-3 items-center">
							<p className="flex-1">Wallet Balance</p>
							<p>{formatCashValue(walletBalance.rounded, 4)}</p>
						</div>

						<div className="flex gap-3 items-center">
							<p className="flex-1">Unclaimed Balance</p>
							<p>{formatCashValue(unclaimedBalance.rounded, 4)}</p>
						</div>

						<div className="flex gap-3">
							<p className="flex-1">Price</p>
							<p>$ {formatCashValue(compPrice.rounded, 2)}</p>
						</div>
					</div>
				</div>
			</div>

			<div>
				<PrimaryButton
					className="w-full"
					disabled={disableClaimButton}
					onClick={claim}
				>
					{disableClaimButton ? 'Nothing to Claim' : 'Claim Citrus'}
				</PrimaryButton>
			</div>
		</>
	)
}
