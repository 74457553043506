import {BigNumber, ethers} from 'ethers'
import {
	TransactionStatus,
	useContractCall,
	useEthers,
	useSendTransaction,
} from '@usedapp/core'

import CErc20Abi from '../abis/CErc20.abi.json'
import CWrappedNativeAbi from '../abis/CWrappedNative.abi.json'
import {Interface} from '@ethersproject/abi'
import {UserMarket} from './useMarkets'
import assetValueToBigNumber from '../utils/assetValueToBigNumber'
import useConstants from './useConstants'

const CWrappedNativeContract = new Interface(CWrappedNativeAbi)
const CErc20Contract = new Interface(CErc20Abi)

export default function useMoveAssets(market: UserMarket): {
	txState: TransactionStatus
	supplyAsset: (value: string) => Promise<void>
	withdrawAsset: (value: string, max?: boolean) => Promise<void>
	borrowAsset: (value: string) => Promise<void>
	repayAsset: (value: string, max?: boolean) => Promise<void>
} {
	const constants = useConstants()
	const {account} = useEthers()
	const {sendTransaction, state} = useSendTransaction()

	const cTokenBalance: BigNumber = (useContractCall({
		abi: CWrappedNativeContract,
		address: market.cTokenAddress,
		method: 'balanceOf',
		args: [account],
	}) ?? [BigNumber.from(0)])[0]

	async function supplyAsset(value: string) {
		const cryptoValue = assetValueToBigNumber(value, market.underlyingDecimals)

		switch (market.asset.type) {
			case 'base':
				await sendTransaction({
					chainId: constants.chainId,
					to: market.cTokenAddress,
					value: cryptoValue,
					data: CWrappedNativeContract.encodeFunctionData('mintNative', []),
				})
				break
			case 'token':
				await sendTransaction({
					chainId: constants.chainId,
					to: market.cTokenAddress,
					data: CErc20Contract.encodeFunctionData('mint', [cryptoValue]),
				})
		}
	}

	async function withdrawAsset(value: string, max?: boolean) {
		let cryptoValue: BigNumber

		if (max) {
			cryptoValue = cTokenBalance
		} else {
			cryptoValue = assetValueToBigNumber(value, market.underlyingDecimals)
		}

		await sendTransaction({
			chainId: constants.chainId,
			to: market.cTokenAddress,
			data: CWrappedNativeContract.encodeFunctionData(
				max
					? market.asset.type === 'base'
						? 'redeemNative'
						: 'redeem'
					: market.asset.type === 'base'
					? 'redeemUnderlyingNative'
					: 'redeemUnderlying',
				[cryptoValue]
			),
		})
	}

	async function borrowAsset(value: string) {
		const cryptoValue = assetValueToBigNumber(value, market.underlyingDecimals)

		await sendTransaction({
			chainId: constants.chainId,
			to: market.cTokenAddress,
			data: CErc20Contract.encodeFunctionData(
				market.asset.type === 'base' ? 'borrowNative' : 'borrow',
				[cryptoValue]
			),
		})
	}

	async function repayAsset(value: string, max?: boolean) {
		let cryptoValue: BigNumber = assetValueToBigNumber(
			value,
			market.underlyingDecimals
		)

		if (max) {
			if (market.asset.type === 'base') {
				cryptoValue = market.balance.bn
			} else {
				cryptoValue = ethers.constants.MaxUint256
			}
		}

		switch (market.asset.type) {
			case 'base':
				await sendTransaction({
					chainId: constants.chainId,
					to: market.cTokenAddress,
					value: cryptoValue,
					data: CWrappedNativeContract.encodeFunctionData(
						'repayBorrowNative',
						[]
					),
				})
				break
			case 'token':
				await sendTransaction({
					chainId: constants.chainId,
					to: market.cTokenAddress,
					data: CErc20Contract.encodeFunctionData('repayBorrow', [cryptoValue]),
				})
		}
	}

	return {
		txState: state,
		supplyAsset,
		withdrawAsset,
		borrowAsset,
		repayAsset,
	}
}
