import {BigNumber} from '@ethersproject/bignumber'

export function roundBigNumber(
	number: BigNumber,
	inputDecimals: number,
	outputDecimals: number = 4
): number {
	return (
		Number(number.toString().slice(0, outputDecimals - inputDecimals)) /
		Math.pow(10, outputDecimals)
	)
}

export function toBigNumber(num: number): BigNumber {
	const [integer, decimals] = num.toString().split('.')

	return BigNumber.from(integer + '0'.repeat(18)).add(
		BigNumber.from((decimals ?? '').padEnd(18, '0'))
	)
}

export type UsableBigNumber = {
	raw: string
	bn: BigNumber
	rounded: number
	// raw big number with decimals
	stringed: string
}

export default function transformBigNumber(
	number: BigNumber,
	inputDecimals: number,
	roundedDecimals: number = 4
): UsableBigNumber {
	const raw = number.toString()
	const zeroedRaw =
		raw.length > inputDecimals ? raw : raw.padStart(inputDecimals + 1, '0')
	const trueDecimals = zeroedRaw.slice(
		zeroedRaw.length - inputDecimals,
		zeroedRaw.length
	)
	const stringed = `${zeroedRaw.slice(
		0,
		zeroedRaw.length - inputDecimals
	)}.${trueDecimals}`
		// remove trailing zeros
		.replace(/0+$/, '')
		.replace(/\.$/, '')

	return {
		bn: number,
		raw,
		rounded: roundBigNumber(number, inputDecimals, roundedDecimals),
		stringed,
	}
}
