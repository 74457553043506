import {
	ActiveTableVaultRow,
	InactiveTableVaultRow,
} from '../../components/Table/VaultRows'

import Container from '../../components/Container/Container'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import Table from '../../components/Table/Table'
import useVaults from '../../hooks/useVaults'
import {VaultModal} from '../../components/Modal/VaultModal'
import {useEthers} from '@usedapp/core'
import useColumnSort from '../../hooks/useColumnSort'
import {useEffect, useState} from 'react'
import Modal from '../../components/Modal/Modal'
import {SecondaryButton} from '../../components/Button/Button'

export default function VaultsPage() {
	return (
		<PageTemplate>
			<Container className="flex flex-col justify-center items-center sm:w-2/3">
				<VaultsTable />
			</Container>
		</PageTemplate>
	)
}

function VaultsTable() {
	const {account, chainId, switchNetwork} = useEthers()
	const [activeVaultsSortingOrder, changeActiveVaultsSortingOrder] =
		useColumnSort()
	const [inactiveVaultsSortingOrder, changeInactiveVaultsSortingOrder] =
		useColumnSort()
	const activeVaults = useVaults(activeVaultsSortingOrder, 'active')
	const inactiveVaults = useVaults(inactiveVaultsSortingOrder, 'inactive')

	const [showSwitchNetworkModal, setShowSwitchNetworkModal] = useState(false)

	useEffect(() => {
		setShowSwitchNetworkModal(chainId != 100)
	}, [chainId])

	return (
		<>
			{showSwitchNetworkModal && (
				<Modal
					isOpened={showSwitchNetworkModal}
					setIsOpened={setShowSwitchNetworkModal}
					closable={false}
				>
					<div>
						<h1 className="text-2xl leading-6 mb-6 font-semibold text-center">
							Wrong network
						</h1>
						<div className="flex justify-center">
							<SecondaryButton onClick={() => switchNetwork(100)}>
								Switch to Gnosis chain
							</SecondaryButton>
						</div>
					</div>
				</Modal>
			)}
			{activeVaults.length > 0 && (
				<h3 className="text-3xl text-center mb-8">Your vaults</h3>
			)}
			<Table
				colNames={['Asset', 'APY', 'Balance']}
				sortableCols={[2]}
				sortFunctions={{2: changeActiveVaultsSortingOrder}}
				hasData={activeVaults.length > 0}
			>
				{activeVaults.map((vault, index) => (
					<ActiveTableVaultRow
						key={vault.id}
						vault={vault}
						index={index}
						vaultModal={<VaultModal vault={vault} />}
					/>
				))}
			</Table>

			{inactiveVaults.length > 0 && (
				<h3 className="text-3xl text-center mb-8">
					{account ? 'Deposit below' : 'Vaults'}
				</h3>
			)}
			<Table
				colNames={['Asset', 'APY', account ? 'Wallet' : 'Total assets']}
				sortableCols={[2]}
				sortFunctions={{2: changeInactiveVaultsSortingOrder}}
				hasData={inactiveVaults.length > 0}
			>
				{inactiveVaults.map((vault, index) => (
					<InactiveTableVaultRow
						key={vault.id}
						vault={vault}
						index={index}
						vaultModal={<VaultModal vault={vault} />}
					/>
				))}
			</Table>
		</>
	)
}
