import {
	TransactionStatus,
	useContractCall,
	useContractCalls,
	useEthers,
	useSendTransaction,
} from '@usedapp/core'
import transformBigNumber, {UsableBigNumber} from '../utils/bigNumber'

import {BigNumber} from 'ethers'
import CompoundLensAbi from '../abis/CompoundLens.abi.json'
import ComptrollerManagerAbi from '../abis/ComptrollerManager.abi.json'
import {Interface} from 'ethers/lib/utils'
import PriceOracleAbi from '../abis/PriceOracle.abi.json'
import useConstants from './useConstants'

const CompoundLensContract = new Interface(CompoundLensAbi)
const ComptrollerManagerContract = new Interface(ComptrollerManagerAbi)
const PriceOracleContract = new Interface(PriceOracleAbi)

const ONE = BigNumber.from(10).pow(18)

export default function useComp(): {
	totalBalance: UsableBigNumber
	walletBalance: UsableBigNumber
	unclaimedBalance: UsableBigNumber
	compPrice: UsableBigNumber
	totalCompValueUsd: UsableBigNumber
	claimComp: () => Promise<void>
	transactionState: TransactionStatus
} {
	const constants = useConstants()
	const {account} = useEthers()
	const {sendTransaction, state} = useSendTransaction()

	const compMetadata = useContractCalls(
		constants.marketPools.map(pool => ({
			abi: CompoundLensContract,
			address: constants.lens,
			method: 'getCompBalanceMetadataExt',
			args: [constants.comp, pool.comptroller, account],
		}))
	) as
		| {
				balance: BigNumber
				votes: BigNumber
				delegate: string
				allocated: BigNumber
		  }[][]

	// temporary until we can get CTRS price from oracle
	const linkAddress = '0xFf864DdC537cf75b8d59FaF27dC023cc20D49483'

	// TODO change to get CTRS price
	const compPrice = (useContractCall({
		abi: PriceOracleContract,
		address: constants.priceOracle,
		method: 'getTokenPrice',
		args: [linkAddress],
		// args: [constants.comp],
	}) ?? [BigNumber.from(0)])[0] as BigNumber

	const walletBalance = compMetadata[0]
		? compMetadata[0][0].balance
		: BigNumber.from(0)
	let unclaimedBalance = BigNumber.from(0)

	compMetadata.forEach(compData => {
		if (!compData) {
			return
		}

		unclaimedBalance = unclaimedBalance.add(compData[0].allocated)
	})

	const totalBalance = walletBalance.add(unclaimedBalance)
	const totalCompValue = totalBalance.mul(compPrice).div(ONE)

	async function claimComp() {
		const comptrollers = constants.marketPools.map(pool => pool.comptroller)

		await sendTransaction({
			chainId: constants.chainId,
			to: constants.comptrollerManager,
			data: ComptrollerManagerContract.encodeFunctionData('claimComp', [
				account,
				comptrollers,
			]),
		})
	}

	const usableTotalBalance = transformBigNumber(totalBalance, 18, 8)
	const usableWalletBalance = transformBigNumber(walletBalance, 18, 8)
	const usableUnclaimedBalance = transformBigNumber(unclaimedBalance, 18, 8)
	const usableCompPrice = transformBigNumber(compPrice, 18, 2)
	const usableTotalCompValue = transformBigNumber(totalCompValue, 18, 2)

	return {
		totalBalance: usableTotalBalance,
		walletBalance: usableWalletBalance,
		unclaimedBalance: usableUnclaimedBalance,
		compPrice: usableCompPrice,
		totalCompValueUsd: usableTotalCompValue,
		claimComp,
		transactionState: state,
	}
}
