import {TransactionState} from '@usedapp/core/dist/cjs/src/model'
import {useEffect, useMemo} from 'react'
import useConstants from '../../hooks/useConstants'
import {SecondaryButton} from '../Button/Button'
import {CheckIcon, LoadingSpinner, XIcon} from '../Icon'

export function SpinnerModal({
	setOpen,
	txStatus,
	txHash,
	txErrorMessage,
	claimAmount,
}: {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	txStatus: TransactionState
	txHash?: string
	txErrorMessage?: string
	claimAmount?: string
}) {
	const network = useConstants()
	const isConfirmed = txStatus === 'Success'
	const txFailed = txStatus === 'Fail' || txStatus === 'Exception'

	useEffect(() => {
		if (txStatus === 'Success') {
			setOpen(false)
		}
		if (
			txErrorMessage ===
			'MetaMask Tx Signature: User denied transaction signature.'
		) {
			setOpen(false)
		}
	}, [txStatus, txErrorMessage, setOpen])

	const modalText = useMemo(() => {
		switch (txStatus) {
			case 'None':
			case 'PendingSignature':
				return {
					header: 'Please Confirm the Transaction',
					body: 'Please confirm the transaction on Metamask',
				}
			case 'Mining':
				return {
					header: 'Transaction Pending',
					body: 'Your transaction is waiting for a confirmation. Please wait.',
				}
			case 'Success':
				return {
					header: 'Transaction Confirmed',
					body: 'Success',
				}
			case 'Exception':
			case 'Fail':
				return {
					header: 'Something went wrong',
					body: 'Please try again.',
				}
		}
	}, [txStatus])

	const explorerUrl = txHash
		? `${network.blockchainExplorer}tx/${txHash}`
		: undefined

	return (
		<>
			<div className="mb-8">
				<div className="text-center">
					<h3 className="text-xl mb-6 font-semibold">{modalText.header}</h3>
					<div className="flex flex-col gap-3 mb-6">
						{isConfirmed ? (
							<CheckIcon className="mx-auto mb-4 text-green-500" size="large" />
						) : txFailed ? (
							<XIcon className="mx-auto mb-4 text-red-500" size="large" />
						) : (
							<LoadingSpinner className="mx-auto mb-4" size="large" />
						)}
						{claimAmount && (
							<p className="text-lg mb-2">Claiming {claimAmount} CTRS</p>
						)}
						<p>{modalText.body}</p>
					</div>
				</div>
			</div>

			{explorerUrl && (
				<div>
					<SecondaryButton
						className="w-full"
						onClick={() => window.open(explorerUrl, '_blank')}
					>
						View on {network.blockchainExplorerName}
					</SecondaryButton>
				</div>
			)}
		</>
	)
}
