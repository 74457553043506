import {Logo, MenuIcon} from '../Icon'
import {useCallback, useEffect} from 'react'

import ClaimButton from '../ClaimButton/ClaimButton'
import ConnectButton from '../ConnectButton/ConnectButton'
import OutsideClickHandler from 'react-outside-click-handler'
import clsx from 'clsx'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import {useToggle} from 'react-use'

export default function NavBar() {
	const [isOpen, toggleOpen] = useToggle(false)
	const [isScrolled, setIsScrolled] = useToggle(false)
	const shouldShowCTRS = useFeatureFlag('token')

	const handleScroll = useCallback(() => {
		if (window.scrollY > 20) {
			setIsScrolled(true)
		} else {
			setIsScrolled(false)
		}
	}, [setIsScrolled])

	useEffect(() => {
		handleScroll()
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [handleScroll])

	return (
		<header
			className={clsx(
				'fixed z-20 top-0 left-0 right-0 px-6 transition-all duration-300 bg-black',
				isScrolled ? 'py-2' : 'py-6 bg-opacity-20'
			)}
		>
			<nav className="flex justify-between relative items-center gap-6 w-full max-w-7xl mx-auto">
				<div>
					<a href="/" className="flex items-center gap-2">
						<Logo
							size={isScrolled ? 'medium' : 'large'}
							className="transition-all duration-500 -mt-2"
						/>
						<span className="flex text-3xl">
							<span className="font-semibold">citrus</span>
							<span className="font-light hidden sm:inline-block">finance</span>
						</span>
					</a>
				</div>

				<div className="flex gap-4 items-center justify-end">
					{shouldShowCTRS && (
						<div className="flex flex-wrap items-center justify-end">
							Balance{' '}
							<span className="flex gap-1.5 items-center ml-3">
								<Logo size="small" className="-mt-1" /> 414.2323
							</span>
						</div>
					)}
					<OutsideClickHandler
						onOutsideClick={() => {
							toggleOpen(false)
						}}
					>
						<button
							type="button"
							className="sm:hidden flex"
							onClick={toggleOpen}
						>
							<MenuIcon className="text-yellow-400" />
						</button>
						<ul
							className={clsx(
								'fixed sm:relative sm:flex justify-end transition-all duration-300 items-center top-0 right-0 sm:bg-transparent divide-y sm:divide-none divide-gray-800 sm:mt-auto rounded-xl text-center bg-gray-900',
								!isOpen && 'hidden',
								isScrolled ? 'mt-16' : 'mt-24'
							)}
						>
							{/* <li>
								<NavLink label="Market" href="/" />
							</li> */}

							{shouldShowCTRS && (
								<>
									<li>
										<NavLink label="Stake" href="/stake" />
									</li>
									<li>
										<ClaimButton />
									</li>
								</>
							)}
							<li className="sm:hidden py-3">
								<ConnectButton />
							</li>
						</ul>
					</OutsideClickHandler>
					<ConnectButton className="hidden sm:inline-block whitespace-nowrap" />
				</div>
			</nav>
		</header>
	)
}

function NavLink({
	label,
	href,
	className,
	target,
}: {
	label: string
	href?: string
	className?: string
	target?: string
}) {
	return (
		<a
			href={href}
			className={clsx(
				'inline-block px-16 sm:px-5 py-5 sm:py-3 w-full font-medium text-yellow-400',
				className,
				href ? 'cursor-pointer' : 'cursor-default'
			)}
			target={target}
			rel="noreferrer"
		>
			{label}
		</a>
	)
}
