import transformBigNumber, {UsableBigNumber} from '../utils/bigNumber'
import {useContractCall, useContractCalls, useEthers} from '@usedapp/core'
import useMarkets, {UserMarket} from './useMarkets'

import {BigNumber} from 'ethers'
import CErc20Abi from '../abis/CErc20.abi.json'
import CompoundLensAbi from '../abis/CompoundLens.abi.json'
import ComptrollerAbi from '../abis/Comptroller.abi.json'
import {Interface} from '@ethersproject/abi'
import assetValueToBigNumber from '../utils/assetValueToBigNumber'
import {convertToUsd} from '../utils/convertToUsd'
import useConstants from './useConstants'
import useCurrentMarketPool from './useCurrentMarketPool'
import {useMemo} from 'react'

const ComptrollerContract = new Interface(ComptrollerAbi)
const CompoundLensContract = new Interface(CompoundLensAbi)
const CErc20Contract = new Interface(CErc20Abi)

const ONE = BigNumber.from(10).pow(18)

export default function useAccountLiquidityCalculations(
	market?: UserMarket,
	diff?: string,
	operation?: 'supply' | 'withdraw' | 'borrow' | 'repay'
): {
	totalSupplied: UsableBigNumber
	totalBorrowed: UsableBigNumber
	borrowLimit: UsableBigNumber
	borrowLimitUsed: string
	newValues?: {
		totalSupplied: UsableBigNumber
		totalBorrowed: UsableBigNumber
		borrowLimit: UsableBigNumber
		borrowLimitUsed: string
	}
	netApy: string
	calculate80PercentBorrowLimit: () => number
} {
	const pool = useCurrentMarketPool()
	const constants = useConstants()
	const {account} = useEthers()
	const allMarkets = useMarkets()
	const cTokenAddresses = pool.markets.map(market => market.cTokenAddress)

	const underlyingPrices = useContractCall({
		abi: CompoundLensContract,
		address: constants.lens,
		method: 'cTokenUnderlyingPriceAll',
		args: [cTokenAddresses],
	}) as {cToken: string; underlyingPrice: BigNumber}[][] | undefined

	const prices = useMemo<
		| Record<string, {bn: BigNumber; num: number; priceDecimals: number}>
		| undefined
	>(() => {
		let _prices: Record<
			string,
			{bn: BigNumber; num: number; priceDecimals: number}
		> = {}
		if (underlyingPrices) {
			for (let i = 0; i < underlyingPrices[0].length; i++) {
				const assetSymbol = pool.markets[i].asset.symbol
				const underlyingDecimals = allMarkets.filter(
					market => market.asset.symbol === assetSymbol
				)[0].underlyingDecimals
				// reverse math done in the contract
				const bigPrice = underlyingPrices[0][i].underlyingPrice.div(
					BigNumber.from(10).pow(18 - underlyingDecimals)
				)
				const stringedPrice = bigPrice.toString()
				const integer = stringedPrice.slice(0, -18)
				const decimal = stringedPrice.slice(-18)
				const price = Number(`${integer}.${decimal}`)

				_prices = {
					..._prices,
					[assetSymbol]: {
						bn: bigPrice,
						num: price,
						priceDecimals: underlyingDecimals,
					},
				}
			}
		}

		return Object.keys(_prices).length === 0 ? undefined : _prices
	}, [underlyingPrices, pool.markets, allMarkets])

	const activeMarkets = useMarkets({
		state: 'active',
		sortByBalanceOrder: 'asc',
	})

	const assetsInAddresses: string[] = (useContractCall({
		abi: ComptrollerContract,
		address: pool.comptroller,
		method: 'getAssetsIn',
		args: [account],
	}) ?? [['']])[0]

	const getAccountSnapshotResult = useContractCalls(
		activeMarkets.map(market => ({
			abi: CErc20Contract,
			address: market.cTokenAddress,
			method: 'getAccountSnapshot',
			args: [account],
		}))
	) as [BigNumber[]]

	let totalSupplied = BigNumber.from(0)
	let totalBorrowed = BigNumber.from(0)
	let liquidity = BigNumber.from(0)
	let borrowLimit = BigNumber.from(0)

	let interest = 0

	activeMarkets.forEach((market, i) => {
		const getAccountSnapshotItem = getAccountSnapshotResult[i]

		if (!getAccountSnapshotItem || !prices) {
			return
		}

		const [, cTokenBalance, borrowBalance, exchangeRateMantissa] =
			getAccountSnapshotItem
		const price = prices[market.asset.symbol].bn
		const priceDecimals = prices[market.asset.symbol].priceDecimals
		const collateralFactor = market.collateralFactor.bn

		const apy = market.apy / 100

		if (market.type === 'supply') {
			const supply = cTokenBalance
				.mul(exchangeRateMantissa)
				.mul(price)
				.div(ONE)
				.div(BigNumber.from(10).pow(priceDecimals))
			const availableSupply = assetsInAddresses
				.map(address => address.toLowerCase())
				.includes(market.cTokenAddress.toLowerCase())
				? supply.mul(collateralFactor).div(ONE)
				: BigNumber.from(0)

			totalSupplied = totalSupplied.add(supply)
			borrowLimit = borrowLimit.add(availableSupply)
			liquidity = liquidity.add(availableSupply)

			const usableSupply = transformBigNumber(supply, 18, 4)
			interest += usableSupply.rounded * apy
		} else if (market.type === 'borrow') {
			const borrow = borrowBalance
				.mul(price)
				.div(BigNumber.from(10).pow(priceDecimals))

			totalBorrowed = totalBorrowed.add(borrow)
			liquidity = liquidity.sub(borrow)

			const usableBorrow = transformBigNumber(borrow, 18, 4)
			interest -= usableBorrow.rounded * apy
		}
	})

	const newValues = useMemo(() => {
		if (market && prices && diff) {
			const cryptoAmount = assetValueToBigNumber(
				diff,
				market.underlyingDecimals
			)
			const amountInUsd = convertToUsd(
				cryptoAmount,
				prices[market.asset.symbol].bn,
				market.underlyingDecimals
			)

			const availableCollateral = amountInUsd.bn
				.mul(market.collateralFactor.bn)
				.div(ONE)

			let newSupplyBalance = BigNumber.from(0)
			let newBorrowBalance = BigNumber.from(0)
			let newBorrowLimit = BigNumber.from(0)
			let newBorrowLimitUsed = 0

			// newBorrowLimitUsed = (newBorrowLimit - liquidity +- (availableCollateral or amountInUsd.bn)) * 1_000_000 / newBorrowLimit / 10_000
			// result of this calculation is in 00.0000 format for better precision
			switch (operation) {
				case 'supply':
					newSupplyBalance = totalSupplied.add(amountInUsd.bn)
					newBorrowLimit = borrowLimit.add(availableCollateral)
					newBorrowLimitUsed = newBorrowLimit.isZero()
						? 0
						: newBorrowLimit
								.sub(liquidity)
								.sub(availableCollateral)
								.mul(1_000_000)
								.div(newBorrowLimit)
								.toNumber() / 10_000
					break
				case 'withdraw':
					newSupplyBalance = totalSupplied.sub(amountInUsd.bn)
					newBorrowLimit = borrowLimit.sub(availableCollateral)
					newBorrowLimitUsed = newBorrowLimit.isZero()
						? 0
						: newBorrowLimit
								.sub(liquidity)
								.add(availableCollateral)
								.mul(1_000_000)
								.div(newBorrowLimit)
								.toNumber() / 10_000
					break
				case 'borrow':
					newBorrowBalance = totalBorrowed.add(amountInUsd.bn)
					newBorrowLimitUsed = borrowLimit.isZero()
						? 0
						: borrowLimit
								.sub(liquidity)
								.add(amountInUsd.bn)
								.mul(1_000_000)
								.div(borrowLimit)
								.toNumber() / 10_000
					break
				case 'repay':
					newBorrowBalance = totalBorrowed.sub(amountInUsd.bn)
					newBorrowLimitUsed = borrowLimit.isZero()
						? 0
						: borrowLimit
								.sub(liquidity)
								.sub(amountInUsd.bn)
								.mul(1_000_000)
								.div(borrowLimit)
								.toNumber() / 10_000
					break
			}

			newBorrowLimitUsed =
				newBorrowLimitUsed > 100
					? 100
					: newBorrowLimitUsed < 0
					? 0
					: newBorrowLimitUsed

			return {
				totalSupplied: transformBigNumber(newSupplyBalance, 18, 4),
				totalBorrowed: transformBigNumber(newBorrowBalance, 18, 4),
				borrowLimit: transformBigNumber(newBorrowLimit, 18, 4),
				borrowLimitUsed: Number(newBorrowLimitUsed.toFixed(2)).toString(),
			}
		}

		return undefined
	}, [
		market,
		prices,
		diff,
		operation,
		totalSupplied,
		totalBorrowed,
		borrowLimit,
		liquidity,
	])

	const usableTotalSupplied = transformBigNumber(totalSupplied, 18, 4)
	const usableTotalBorrowed = transformBigNumber(totalBorrowed, 18, 4)
	const usableBorrowLimit = transformBigNumber(borrowLimit, 18, 4)
	const usableLiquidity = transformBigNumber(liquidity, 18, 4)

	const borrowLimitUsed = borrowLimit.isZero()
		? 0
		: ((usableBorrowLimit.rounded - usableLiquidity.rounded) /
				usableBorrowLimit.rounded) *
		  100

	const netApy = usableTotalSupplied.bn.isZero()
		? 0
		: (interest / usableTotalSupplied.rounded) * 100

	function calculate80PercentBorrowLimit(): number {
		if (borrowLimitUsed < 80 && prices && market) {
			const missingPercentages = (80 - borrowLimitUsed) / 100
			const missingAmount = usableBorrowLimit.rounded * missingPercentages
			const price = prices[market.asset.symbol].num

			return missingAmount / price
		}

		return 0
	}

	return {
		totalSupplied: usableTotalSupplied,
		totalBorrowed: usableTotalBorrowed,
		borrowLimit: usableBorrowLimit,
		borrowLimitUsed: Number(borrowLimitUsed.toFixed(2)).toString(),
		newValues,
		netApy: netApy.toFixed(2),
		calculate80PercentBorrowLimit,
	}
}
