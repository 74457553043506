import useAccountLiquidityCalculations from '../../hooks/useAccountLiquidityCalculations'
import formatCashValue from '../../utils/formatCashValue'
import Container from '../Container/Container'

export default function MarketHeader() {
	const {totalSupplied, totalBorrowed, borrowLimit, borrowLimitUsed, netApy} =
		useAccountLiquidityCalculations()

	return (
		<Container className="relative pb-0 pt-8 sm:pt-16">
			<div className="flex flex-col sm:flex-row gap-8 sm:gap-20 items-center justify-center mb-12 sm:mb-14">
				{/* <TotalTile label="Total Supply" value="$ 23,784,362.68" />
							<TotalTile label="Net APY" value="- 64.30 %" />
							<TotalTile label="Total Borrow" value="$ 538,362.68" /> */}
				<div className="text-center">
					<h3 className="mb-4">Total Supply</h3>
					<div className="text-3xl truncate">
						$ {formatCashValue(totalSupplied.rounded, 2)}
					</div>
				</div>
				<div className="text-center">
					<h3 className="mb-4">Net APY</h3>
					<div className="text-3xl truncate">{netApy.replace('-', '- ')} %</div>
				</div>
				<div className="text-center">
					<h3 className="mb-4">Total Borrow</h3>
					<div className="text-3xl truncate">
						$ {formatCashValue(totalBorrowed.rounded, 2)}
					</div>
				</div>
			</div>
			<div className="relative w-full lg:w-2/3 mx-auto rounded-full glow-blue">
				<div className="px-12 pt-5 pb-8 rounded-full card-blur">
					<div className="relative flex mb-2 items-end justify-between">
						<div>Borrow limit</div>
						<div className="text-right divide-x">
							<span className="pr-4 whitespace-nowrap">{borrowLimitUsed}%</span>
							<span className="pl-4">
								$ {formatCashValue(borrowLimit.rounded, 2)}
							</span>
						</div>
					</div>
					<div className="relative overflow-hidden h-2 flex rounded bg-gray-800">
						<div
							style={{width: `${borrowLimitUsed}%`, filter: 'brightness(125%)'}}
							className="flex flex-col text-center whitespace-nowrap justify-center bg-green-500"
						/>
					</div>
				</div>
			</div>
		</Container>
	)
}
