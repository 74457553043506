import {
	TransactionStatus,
	useContractCall,
	useSendTransaction,
} from '@usedapp/core'

import ComptrollerAbi from '../abis/Comptroller.abi.json'
import {Interface} from '@ethersproject/abi'
import {UserMarket} from './useMarkets'
import useConstants from './useConstants'
import useCurrentMarketPool from './useCurrentMarketPool'
import {useEthers} from '@usedapp/core'

const ComptrollerContract = new Interface(ComptrollerAbi)

export type UseCollateralReturn = {
	isEnabledAsCollateral: boolean
	enableAsCollateral: () => Promise<void>
	disableAsCollateral: () => Promise<void>
	transactionState: TransactionStatus
}

export default function useCollateral(market: UserMarket): UseCollateralReturn {
	const pool = useCurrentMarketPool()

	const constants = useConstants()
	const {account} = useEthers()
	const {sendTransaction, state} = useSendTransaction()

	const isEnabledAsCollateral: boolean = (useContractCall({
		abi: ComptrollerContract,
		address: pool.comptroller,
		method: 'checkMembership',
		args: [account, market.cTokenAddress],
	}) ?? [])[0]

	async function enableAsCollateral() {
		await sendTransaction({
			chainId: constants.chainId,
			to: pool.comptroller,
			data: ComptrollerContract.encodeFunctionData('enterMarkets', [
				[market.cTokenAddress],
			]),
		})
	}

	async function disableAsCollateral() {
		await sendTransaction({
			chainId: constants.chainId,
			to: pool.comptroller,
			data: ComptrollerContract.encodeFunctionData('exitMarket', [
				market.cTokenAddress,
			]),
		})
	}

	return {
		isEnabledAsCollateral,
		enableAsCollateral,
		disableAsCollateral,
		transactionState: state,
	}
}
