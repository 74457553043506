import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useRef} from 'react'
import {XIcon} from '../Icon'

export default function Modal({
	children,
	isOpened,
	setIsOpened,
	closable = true,
}: {
	children: React.ReactNode
	isOpened: boolean
	setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
	closable?: boolean
}) {
	const cancelButtonRef = useRef(null)

	return (
		<Transition.Root show={isOpened} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-30 inset-0 overflow-y-auto"
				onClose={closable ? setIsOpened : () => setIsOpened(true)}
				initialFocus={cancelButtonRef}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
							style={{backdropFilter: 'blur(3px)'}}
						/>
					</Transition.Child>

					{/* This element centers the modal contents */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-black text-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full p-6 glow-blue">
							{closable && (
								<button
									className="focus:outline-none absolute hover:text-yellow-400 text-gray-700 top-0 right-0 p-2 rounded-full transition-all"
									onClick={() => setIsOpened(false)}
									ref={cancelButtonRef}
								>
									<XIcon size="small" />
								</button>
							)}
							{children}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
