import {SecondaryButton} from '../Button/Button'
import {CoinbaseLogo, MetaMaskLogo, WalletConnectLogo} from '../Icon'

export default function ChooseWalletModal({
	activate,
}: {
	activate: (connectorName: 'metaMask' | 'walletLink' | 'walletConnect') => void
}) {
	const isMetaMaskInjected = window.ethereum && window.ethereum.isMetaMask

	return (
		<div className="flex flex-col gap-5 items-center py-2 px-3">
			<div className="flex justify-between items-center w-full gap-4">
				<MetaMaskLogo />
				<SecondaryButton
					onClick={() => {
						if (isMetaMaskInjected) {
							activate('metaMask')
						} else {
							window.open('https://metamask.io/download/', '_blank')
						}
					}}
				>
					{isMetaMaskInjected ? 'Connect' : 'Install'}
				</SecondaryButton>
			</div>
			<div className="flex justify-between items-center w-full gap-4">
				<CoinbaseLogo className="w-32" />
				<SecondaryButton onClick={() => activate('walletLink')}>
					Connect
				</SecondaryButton>
			</div>
			<div className="flex justify-between items-center w-full gap-4">
				<WalletConnectLogo className=" w-52" />
				<SecondaryButton onClick={() => activate('walletConnect')}>
					Connect
				</SecondaryButton>
			</div>
		</div>
	)
}
