import React from 'react'
import clsx from 'clsx'

export default function Container({
	children,
	className,
	cols2 = false,
}: {
	children: React.ReactNode
	className?: string
	cols2?: boolean
}) {
	return (
		<section
			className={clsx(
				'w-full sm:max-w-7xl mx-auto px-1 sm:px-8 py-16',
				cols2 && 'space-y-8 md:space-y-0 md:grid grid-cols-2 gap-10',
				className
			)}
		>
			{children}
		</section>
	)
}
