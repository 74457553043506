import {useEffect} from 'react'
import _ from 'lodash'
import ky from 'ky'
import {toBigNumber} from '../utils/bigNumber'
import {BigNumber} from 'ethers'

const priceMap = new Map<string, number>()

const updatePrices = _.throttle(async () => {
	const keys = Array.from(priceMap.keys())

	const result = await ky
		.get(`https://coins.llama.fi/prices/current/${keys.join(',')}`)
		.json<{
			coins: Record<string, {price: number}>
		}>()

	for (const key in result.coins) {
		priceMap.set(key, result.coins[key].price)
	}
}, 1000)

// FIXME: this is not conform to react flow, we should probably use useSyncExternalStore
export function useDollarPrice(
	chainId: number,
	tokenAddress: string
): BigNumber {
	const key = `gnosis:${tokenAddress.toLowerCase()}`

	useEffect(() => {
		if (!priceMap.has(key)) {
			priceMap.set(key, 0)
		}

		updatePrices()
	}, [chainId, tokenAddress])

	return toBigNumber(priceMap.get(key) ?? 0)
}
