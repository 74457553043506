import {NetworkConfig} from '../types/NetworkConfig'
import {networks} from '../constants'
import {useEthers} from '@usedapp/core'

const wrongNetworkConfig: NetworkConfig = {
	chainId: 0,
	name: 'Wrong network',
	blocksPerDay: 0,
	rpc: '',
	lens: '',
	comp: '',
	comptrollerManager: '',
	priceOracle: '',
	marketPools: [],
	blockchainExplorerName: '',
	blockchainExplorer: '',
	erc4626Router: '',
	vaultLens: '',
	vaults: [],
}

export default function useConstants(): NetworkConfig {
	const {chainId} = useEthers()

	if (!chainId) return wrongNetworkConfig

	if (!(chainId in networks)) return wrongNetworkConfig

	// @ts-ignore
	return networks[chainId]
}
