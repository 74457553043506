import {Route, Routes} from 'react-router-dom'
import './App.css'
import LandingPage from './pages/LandingPage/LandingPage'
import StakePage from './pages/StakePage/StakePage'
import VaultsPage from './pages/VaultsPage/VaultsPage'

function App() {
	return (
		<Routes>
			<Route index element={<LandingPage />} />
			<Route path="/stake" element={<StakePage />} />
			<Route path="/vaults" element={<VaultsPage />} />
		</Routes>
	)
}

export default App
