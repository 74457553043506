import {ChainId, useEthers, useSendTransaction} from '@usedapp/core'

import {BigNumber} from '@ethersproject/bignumber'
import ERC20MockAbi from '../abis/ERC20Mock.abi.json'
import {Interface} from '@ethersproject/abi'
import {UserMarket} from './useMarkets'
import useConstants from './useConstants'

const ERC20MockContract = new Interface(ERC20MockAbi)

export default function useFaucet(market: UserMarket) {
	const constants = useConstants()
	const {account} = useEthers()

	const allowed = Boolean(account) && constants.chainId === ChainId.Rinkeby

	const {sendTransaction, state} = useSendTransaction()

	return {
		faucetAllowed: allowed,
		async getMockToken() {
			if (!allowed) {
				throw new Error('faucet only available on Rinkeby')
			}

			if (market.asset.type === 'base') {
				return window.open('https://faucets.chain.link/rinkeby')
			}

			await sendTransaction({
				chainId: constants.chainId,
				to: market.underlyingAssetAddress,
				data: ERC20MockContract.encodeFunctionData('mint', [
					account,
					BigNumber.from(100).mul(
						BigNumber.from(10).pow(market.underlyingDecimals)
					),
				]),
			})
		},
		faucetState: state,
	}
}
