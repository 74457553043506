import transformBigNumber, {UsableBigNumber} from '../utils/bigNumber'
import {useContractCall, useEtherBalance} from '@usedapp/core'

import {BigNumber} from 'ethers'
import CompoundLensAbi from '../abis/CompoundLens.abi.json'
import {Interface} from 'ethers/lib/utils'
import {Market} from '../types/Market'
import sortMarketsOrVaults from '../utils/sortMarketsOrVaults'
import useConstants from './useConstants'
import useCurrentMarketPool from './useCurrentMarketPool'
import {useEthers} from '@usedapp/core'

const CompoundLensContract = new Interface(CompoundLensAbi)

export type UserMarket = Market & {
	id: string
	type: 'borrow' | 'supply'
	state: 'active' | 'inactive'
	apy: number
	balance: UsableBigNumber
	walletBalance: UsableBigNumber
	underlyingDecimals: number
	underlyingAssetAddress: string
	collateralFactor: UsableBigNumber
}

type CTokenMetadata = {
	cToken: string
	exchangeRateCurrent: BigNumber
	supplyRatePerBlock: BigNumber
	borrowRatePerBlock: BigNumber
	reserveFactorMantissa: BigNumber
	totalBorrows: BigNumber
	totalReserves: BigNumber
	totalSupply: BigNumber
	totalCash: BigNumber
	isListed: boolean
	collateralFactorMantissa: BigNumber
	underlyingAssetAddress: string
	cTokenDecimals: BigNumber
	underlyingDecimals: BigNumber
}

type CTokenBalances = {
	cToken: string
	balanceOf: BigNumber
	borrowBalanceCurrent: BigNumber
	balanceOfUnderlying: BigNumber
	tokenBalance: BigNumber
	tokenAllowance: BigNumber
}

const ZERO = BigNumber.from(0)

export default function useMarkets(
	{
		type,
		state,
		sortByBalanceOrder,
	}: {
		type?: 'borrow' | 'supply'
		state?: 'active' | 'inactive'
		sortByBalanceOrder: 'asc' | 'desc'
	} = {sortByBalanceOrder: 'desc'}
): UserMarket[] {
	const {account} = useEthers()

	const constants = useConstants()

	const ethBalance = useEtherBalance(account) || ZERO

	const pool = useCurrentMarketPool()
	const cTokens = pool.markets.map(x => x.cTokenAddress)

	const cTokenMetadataAllResult: CTokenMetadata[][] =
		useContractCall({
			abi: CompoundLensContract,
			address: constants.lens,
			method: 'cTokenMetadataAll',
			args: [cTokens],
		}) ?? []

	const cTokensMetadata: CTokenMetadata[] = cTokenMetadataAllResult[0] ?? []

	const cTokenBalancesAllResult: CTokenBalances[][] =
		useContractCall({
			abi: CompoundLensContract,
			address: constants.lens,
			method: 'cTokenBalancesAll',
			args: [cTokens, account],
		}) ?? []

	const cTokensBalances: CTokenBalances[] =
		cTokenBalancesAllResult[0] ??
		cTokensMetadata.map(
			(x): CTokenBalances => ({
				cToken: x.cToken,
				balanceOf: BigNumber.from(0),
				borrowBalanceCurrent: BigNumber.from(0),
				balanceOfUnderlying: BigNumber.from(0),
				tokenBalance: BigNumber.from(0),
				tokenAllowance: BigNumber.from(0),
			})
		)

	const markets: UserMarket[] = []

	if (pool.markets.length === cTokensMetadata.length) {
		pool.markets.forEach((market, i) => {
			const metadata = cTokensMetadata[i]
			const balances = cTokensBalances[i]

			const supplyBalance = balances.balanceOfUnderlying
			const borrowBalance = balances.borrowBalanceCurrent

			const balanceOf =
				market.asset.type === 'base' ? ethBalance : balances.tokenBalance

			const supplyRatePerBlock = metadata.supplyRatePerBlock
			const borrowRatePerBlock = metadata.borrowRatePerBlock

			const isActiveSupply = !supplyBalance.isZero()
			const isActiveBorrow = !borrowBalance.isZero()
			const isActive = isActiveSupply || isActiveBorrow

			const depositApy =
				Math.round(
					(Math.pow(
						(supplyRatePerBlock.toNumber() / 1e18) * constants.blocksPerDay + 1,
						365
					) -
						1) *
						10000
				) / 100
			const borrowApy =
				Math.round(
					(Math.pow(
						(borrowRatePerBlock.toNumber() / 1e18) * constants.blocksPerDay + 1,
						365
					) -
						1) *
						10000
				) / 100

			if (!isActive) {
				// display both supply and borrow if none is active

				markets.push({
					...market,
					id: `${market.asset.symbol}-supply`,
					type: 'supply',
					state: 'inactive',
					balance: transformBigNumber(
						supplyBalance,
						metadata.underlyingDecimals.toNumber(),
						4
					),
					walletBalance: transformBigNumber(
						balanceOf,
						metadata.underlyingDecimals.toNumber(),
						4
					),
					apy: depositApy,
					underlyingDecimals: metadata.underlyingDecimals.toNumber(),
					underlyingAssetAddress: metadata.underlyingAssetAddress,
					collateralFactor: transformBigNumber(
						metadata.collateralFactorMantissa,
						18,
						2
					),
				})
				if (market.asset.isBorrowable) {
					markets.push({
						...market,
						id: `${market.asset.symbol}-borrow`,
						type: 'borrow',
						state: 'inactive',
						balance: transformBigNumber(
							borrowBalance,
							metadata.underlyingDecimals.toNumber(),
							4
						),
						walletBalance: transformBigNumber(
							balanceOf,
							metadata.underlyingDecimals.toNumber(),
							4
						),
						apy: borrowApy,
						underlyingDecimals: metadata.underlyingDecimals.toNumber(),
						underlyingAssetAddress: metadata.underlyingAssetAddress,
						collateralFactor: transformBigNumber(
							metadata.collateralFactorMantissa,
							18,
							2
						),
					})
				}
			} else {
				// display only the active

				if (isActiveSupply) {
					markets.push({
						...market,
						id: `${market.asset.symbol}-supply`,
						type: 'supply',
						state: 'active',
						balance: transformBigNumber(
							supplyBalance,
							metadata.underlyingDecimals.toNumber(),
							4
						),
						walletBalance: transformBigNumber(
							balanceOf,
							metadata.underlyingDecimals.toNumber(),
							4
						),
						apy: depositApy,
						underlyingDecimals: metadata.underlyingDecimals.toNumber(),
						underlyingAssetAddress: metadata.underlyingAssetAddress,
						collateralFactor: transformBigNumber(
							metadata.collateralFactorMantissa,
							18,
							2
						),
					})
				}
				if (isActiveBorrow && market.asset.isBorrowable) {
					markets.push({
						...market,
						id: `${market.asset.symbol}-borrow`,
						type: 'borrow',
						state: 'active',
						balance: transformBigNumber(
							borrowBalance,
							metadata.underlyingDecimals.toNumber(),
							4
						),
						walletBalance: transformBigNumber(
							balanceOf,
							metadata.underlyingDecimals.toNumber(),
							4
						),
						apy: borrowApy,
						underlyingDecimals: metadata.underlyingDecimals.toNumber(),
						underlyingAssetAddress: metadata.underlyingAssetAddress,
						collateralFactor: transformBigNumber(
							metadata.collateralFactorMantissa,
							18,
							2
						),
					})
				}
			}
		})
	}

	return sortMarketsOrVaults(
		markets,
		state === 'active' ? 'balance' : 'walletBalance',
		sortByBalanceOrder
	)
		.filter(x => (type ? x.type === type : true))
		.filter(x => (state ? x.state === state : true))
}
