export default function formatCashValue(
	value: number,
	decimals?: number
): string {
	const stringedValue =
		decimals === undefined ? value.toString() : value.toFixed(decimals)

	const [integer, decimal] = stringedValue.split('.')
	const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

	return decimal ? `${formattedInteger}.${decimal}` : formattedInteger
}
