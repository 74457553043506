import {UserMarket} from '../hooks/useMarkets'
import {UserVault} from '../hooks/useVaults'

export default function sortMarketsOrVaults<T extends UserMarket | UserVault>(
	array: T[],
	sortBy: 'balance' | 'walletBalance',
	order: 'asc' | 'desc'
): T[] {
	switch (order) {
		case 'asc':
			return array.sort((a, b) => a[sortBy].rounded - b[sortBy].rounded)
		case 'desc':
			return array.sort((a, b) => b[sortBy].rounded - a[sortBy].rounded)
	}
}
