import {ChainId} from '@usedapp/core'
import {Market} from './types/Market'
import {NetworkConfig} from './types/NetworkConfig'
import xdaiDeployment from './deployments/xdai.json'

// src: https://tokens.honeyswap.org/
const xDaiMarkets: Market[] = [
	{
		asset: {
			type: 'base',
			symbol: 'WXDAI',
			name: 'xDai',
			logoURI:
				'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/gnosis/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d/logo.png',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'STAKE',
			name: 'Stake',
			logoURI:
				'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0Ae055097C6d159879521C384F1D2123D1f195e6/logo.png',
			// address: '0xb7D311E2Eb55F2f68a9440da38e7989210b9A05e',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'USDC',
			name: 'USD Coin',
			logoURI:
				'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
			// address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'HNY',
			name: 'Honey',
			logoURI:
				'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/xdai/0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9/logo.png',
			// address: '0xb371248dd0f9e4061ccf8850e9223ca48aa7ca4b',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'WETH',
			name: 'Wrapped Ether',
			logoURI:
				'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
			// address: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'WBTC',
			name: 'Wrapped BTC',
			logoURI:
				'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/ethereum/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599/logo.png',
			// address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'LINK',
			name: 'ChainLink Token',
			logoURI:
				'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png',
			// address: '0xE2e73A1c69ecF83F464EFCE6A5be353a37cA09b2',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'DXD',
			name: 'DXdao',
			logoURI: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5589.png',
			// address: '0xb90D6bec20993Be5d72A5ab353343f7a0281f158',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	{
		asset: {
			type: 'token',
			symbol: 'LEVIN',
			name: 'Levin',
			logoURI:
				'https://raw.githubusercontent.com/Levinswap/default-token-list/master/src/assets/xdai/0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9/logo.png',
			// address: '0x1698cD22278ef6E7c0DF45a8dEA72EDbeA9E42aa',
			isBorrowable: true,
		},
		cTokenAddress: '',
	},
	// {
	//	 type: 'token',
	//	 symbol: '',
	//	 name: '',
	//	 decimals: 18,
	//	 logoURI: '',
	//   address: '',
	//	 cTokenAddress: '',
	// },
]

const rinkebyPrimaryMarketPool: Market[] = [
	{
		asset: {
			type: 'base',
			symbol: 'xDai',
			name: 'xDai',
			logoURI:
				'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/gnosis/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d/logo.png',
			isBorrowable: true,
		},
		cTokenAddress: '0xE1A1d567dDe53E3397Ed99d0D6365e8c56E7d31a',
	},
	{
		asset: {
			type: 'token',
			symbol: 'LINK',
			name: 'Chainlink',
			logoURI:
				'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png',
			isBorrowable: true,
		},
		cTokenAddress: '0x9791F34C27C6a6848a69fCa75C1e2830Ba934b1f',
	},
	{
		asset: {
			type: 'token',
			symbol: 'USDC',
			name: 'USD Coin',
			logoURI:
				'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
			isBorrowable: true,
		},
		cTokenAddress: '0x7ef1151300Ec72665D9bf2be75a3a730Bd387a78',
	},
	{
		asset: {
			type: 'token',
			symbol: 'WBTC',
			name: 'Wrapped BTC',
			logoURI:
				'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/ethereum/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599/logo.png',
			isBorrowable: true,
		},
		cTokenAddress: '0x400508030e7a8A55Ede0E7EddDDdB2FDE78bC170',
	},
]

type NetworkChainId = ChainId.Rinkeby | ChainId.xDai

export const networks: Record<NetworkChainId, NetworkConfig> = {
	[ChainId.Rinkeby]: {
		chainId: ChainId.Rinkeby,
		name: 'rinkeby',
		blocksPerDay: 86400,
		lens: '0x2B5c1D3B8A8538a81AA44d08cf76D8b25968cbF7',
		comp: '0xD397A2817E9160C20611AaB3Cea62b354E6F6BE0',
		comptrollerManager: '0xFbFbc6FAB0FE84ac0cC4b8b7f04955Af7432B0eD',
		priceOracle: '0x958dc4b491106F08CDbF4Ef18ffcB8CA5F9e3773',
		rpc: 'https://apis.ankr.com/b1e3794a047c4f05939e315b1bf27e66/b10685972aae9888d9b0400ca49a04ab/eth/fast/rinkeby',
		marketPools: [
			{
				id: 'primary',
				name: 'Primary',
				comptroller: '0x0A2b8eB63a78214E7Fc4db3ee75D54864A26CeD6',
				markets: rinkebyPrimaryMarketPool,
			},
		],
		blockchainExplorerName: 'Etherscan',
		blockchainExplorer: 'https://rinkeby.etherscan.io/',
		erc4626Router: 'TODO',
		vaultLens: 'TODO',
		vaults: [],
	},
	[ChainId.xDai]: {
		chainId: ChainId.xDai,
		name: 'xDai',
		blocksPerDay: 86400,
		lens: 'TODO',
		comp: 'TODO',
		comptrollerManager: 'TODO',
		priceOracle: 'TODO',
		rpc: 'https://rpc.gnosis.gateway.fm',
		marketPools: [
			{
				id: 'primary',
				name: 'Primary',
				comptroller: 'TODO',
				markets: xDaiMarkets,
			},
		],
		blockchainExplorerName: 'GnosisScan',
		blockchainExplorer: 'https://gnosisscan.io/',
		erc4626Router: xdaiDeployment.erc4626Router,
		vaultLens: xdaiDeployment.vault.lens,
		vaults: xdaiDeployment.vault.vaults,
	},
}

export const allowedNetworksIds = Object.keys(networks).map(Number)

export const RPCs: {[chainId in NetworkChainId]: string} = Object.keys(
	networks
).reduce<{[chainId in NetworkChainId]: string}>((accumulator, chainId) => {
	const validChainId = Number(chainId) as NetworkChainId
	const rpc: string = networks[validChainId].rpc

	accumulator[validChainId] = rpc

	return accumulator
}, {} as {[chainId in NetworkChainId]: string})
