import {useEffect, useState} from 'react'
import useAccountLiquidityCalculations from '../../hooks/useAccountLiquidityCalculations'
import useCollateral from '../../hooks/useCollateral'
import {UserMarket} from '../../hooks/useMarkets'
import formatCashValue from '../../utils/formatCashValue'
import PrimaryButton from '../Button/Button'
import Modal from './Modal'
import {SpinnerModal} from './SpinnerModal'

export function EnableAsCollateralModal({
	market,
	onClose,
}: {
	market: UserMarket
	onClose: () => void
}) {
	const {
		isEnabledAsCollateral,
		enableAsCollateral,
		disableAsCollateral,
		transactionState,
	} = useCollateral(market)
	const {borrowLimit, borrowLimitUsed, newValues} =
		useAccountLiquidityCalculations(
			market,
			market.balance.stringed,
			isEnabledAsCollateral ? 'withdraw' : 'supply'
		)
	const [open, setOpen] = useState<boolean>(false)

	useEffect(() => {
		if (transactionState.status === 'Success') {
			onClose()
		}
	}, [transactionState, onClose])

	const modalText =
		Number(newValues?.borrowLimitUsed) === 100
			? {
					header: 'Collateral Required',
					body: 'This asset is required to support your borrowed assets. Either repay borrowed assets, or supply another asset as collateral.',
					button: `Cancel`,
			  }
			: isEnabledAsCollateral
			? {
					header: 'Disable as Collateral',
					body: "This asset will no longer be used towards your borrowing limit, and can't be seized in liquidation.",
					button: `Disable ${market.asset.name}`,
			  }
			: {
					header: 'Enable as Collateral',
					body: 'Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation.',
					button: `Use ${market.asset.name} as Collateral`,
			  }

	function handleConfirm() {
		if (Number(newValues?.borrowLimitUsed) === 100) {
			onClose()
			return
		}

		setOpen(true)
		if (isEnabledAsCollateral) {
			disableAsCollateral()
		} else {
			enableAsCollateral()
		}
	}

	return (
		<>
			<Modal isOpened={open} setIsOpened={setOpen}>
				<SpinnerModal
					setOpen={setOpen}
					txStatus={transactionState.status}
					txHash={transactionState.transaction?.hash}
					txErrorMessage={transactionState.errorMessage}
				/>
			</Modal>
			<div className="flex flex-col gap-4 mb-4">
				<h3 className="text-xl leading-6 font-semibold text-center">
					{modalText.header}
				</h3>
				<p className="text-sm text-gray-500 text-center">{modalText.body}</p>
			</div>

			<div className="pt-6 pb-7 flex flex-col gap-4 text-left">
				<div className="flex gap-3">
					<p className="flex-1">Borrow limit</p>
					<p>
						$ {formatCashValue(borrowLimit.rounded, 2)}{' '}
						<span className="text-blue-500">&rarr;</span> ${' '}
						{newValues
							? formatCashValue(newValues.borrowLimit.rounded, 2)
							: formatCashValue(borrowLimit.rounded, 2)}
					</p>
				</div>

				<div className="flex gap-3">
					<p className="flex-1">Borrow limit used</p>
					<p>
						{borrowLimitUsed} % <span className="text-blue-500">&rarr;</span>{' '}
						{isEnabledAsCollateral && newValues
							? newValues.borrowLimitUsed
							: borrowLimitUsed}{' '}
						%
					</p>
				</div>
			</div>

			<PrimaryButton className="w-full" onClick={handleConfirm}>
				{modalText.button}
			</PrimaryButton>
		</>
	)
}
