import {useState} from 'react'

type ColumnSortingOrders = 'asc' | 'desc'

type Returns = [ColumnSortingOrders, () => void]

export default function useColumnSort(): Returns {
	const [columnSortingOrder, setColumnSortingOrder] =
		useState<ColumnSortingOrders>('desc')

	function changeColumnSortingOrder() {
		switch (columnSortingOrder) {
			case 'asc':
				setColumnSortingOrder('desc')
				break
			case 'desc':
				setColumnSortingOrder('asc')
				break
		}
	}

	return [columnSortingOrder, changeColumnSortingOrder]
}
